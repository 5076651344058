.client-signature-container {
  display: flex;
  flex-direction: column;
  width: 78px;
}

.client-signature {
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 9px 12px;
}

.client-signature-active {
  @extend .client-signature;
  background: #009e10;
}

.client-signature-text {
  color: #e0e8ed;
  font-size: 16px;
  text-transform: none;
  margin-left: 8px;
}

.client-signature-inactive {
  @extend .client-signature;
  border: 1px solid #96a1a8;
}

.client-signature-date {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  color: #b8bfc4;
  margin-top: 4px;
}
