@import '@assets/styles/variables.scss';
@import '@assets/styles/extends.scss';

.trip-details-container {
  color: $color-grey-dark-2;
  display: flex;
  flex-direction: column;

  .row {
    @extend .flex;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  ul.details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 20px;
      width: 100px;

      &:last-child {
        flex: 0 0 40%;
      }

      .label {
        color: $color-grey-middle-1;
        font-size: 12px;
        margin-bottom: 8px;
      }

      .value {
        align-items: center;
        background-color: $color-blue-light;
        color: $color-grey-middle-1;
        font-size: 14px;
        display: flex;
        height: 30px;
        overflow: hidden;
        padding: 0 8px;
        position: relative;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .more-details {
    display: flex;
    flex-direction: column;

    &-panel {
      @extend .flex-row;
      color: $color-grey-middle-1;
      cursor: pointer;
      font-size: 12px;
      justify-content: flex-end;

      &:before {
        background-color: $color-grey-light-1;
        content: '';
        height: 1px;
        width: 100%;
      }

      &-header {
        display: flex;
        justify-content: space-between;
        margin-left: 10px;
        min-width: 150px;

        .btn-more-details {
          display: inline-block;
          margin-left: 10px;
          width: 20px;

          svg {
            fill: $color-grey-middle-1;
          }
        }
      }
    }

    .content {
      @extend .flex;
      display: none;
      flex-direction: column;
      overflow: hidden;
      transition: all 0.3s ease-out;

      &.open {
        display: flex;
        transition: all 0.3s ease-out;
      }

      h4 {
        align-self: flex-start;
        display: flex;
        font-size: 14px;

        & + span {
          display: inline-block;
          font-size: 10px;
          line-height: 1.2;
          margin-top: 10px;
        }
      }

      ul.details {
        margin: 0;
        padding: 0 120px 0 30px;
        width: 100%;

        li {
          flex: 0 0 24%;
          margin-bottom: 20px;

          &:last-child {
            flex: 0 0 24%;
          }
        }
      }
    }
  }

  .arrow-round-up {
    transform: rotateX('180deg');
  }
}
