@import '@assets/styles/variables.scss';

.modal-recap-booking {
  &-header {
    border-bottom: 1px solid $color-grey-dark-1;
    color: $color-grey-dark-1;

    h2 {
      font-size: 18px;
      font-weight: bold;
    }
  }

  &-body {
    padding: 2em !important;
  }

  .recap-booking {
    h2 {
      color: $color-blue-secondary;
      display: flex;
      font-size: 16px;
      font-weight: bold;
      gap: 2em;

      &:not(:first-child) {
        margin: 2em 0 1em;
      }
    }

    &-section {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;

      li {
        background-color: $color-blue-light;
        border: 1px solid $color-grey-light-1;
        border-radius: $border-radius-small;
        display: flex;
        flex-direction: column;
        flex: 1 0 180px;
        padding: 0.75em 1em;

        .recap-item-label {
          font-size: 11px;
          text-transform: uppercase;
        }

        .recap-item-value {
          font-size: 14px;
          font-weight: 500;
          overflow: hidden;
          position: relative;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 180px;
        }

        &.attachment,
        &.remarks {
          .recap-item-value {
            max-width: 100%;
          }
        }
      }
    }
  }

  footer {
    display: flex;
    justify-content: space-between;
    margin: 2em 0 0;
  }
}
