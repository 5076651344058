@import '@assets/styles/variables.scss';

.time-picker {
  &-label {
    display: inline-block;
    word-break: break-word;

    span {
      color: #fa5252;
    }
  }

  &-input {
    margin: 0 0 2px;
    position: relative;

    .react-datepicker {
      &__time {
        border-radius: 0.25rem;
      }

      &__triangle {
        left: -50% !important;
        margin-left: 0 !important;
      }
    }

    input {
      appearance: none;
      background-color: $color-white;
      border-radius: 0.25rem;
      border: 0.0625rem solid $theme-light-input-border;
      box-sizing: border-box;
      color: $color-black;
      cursor: pointer;
      display: block;
      font-family: $font-default;
      font-size: 0.875rem;
      height: 2.25rem;
      line-height: calc(2.25rem - 0.125rem);
      min-height: 2.25rem;
      padding-left: calc(2.25rem / 3);
      padding-right: calc(2.25rem / 3);
      resize: none;
      outline: none;
      text-align: left;
      transition: border-color 100ms ease;
      width: 100%;

      &:focus {
        border-color: #228be6;
      }

      &.invalid {
        border-color: #fa5252;
        color: #fa5252;
      }

      &::placeholder {
        font-size: 0.875rem;
      }
    }

    svg {
      appearance: none;
      pointer-events: none;
      position: absolute;
      right: 10px;
      top: 0.55rem;
    }
  }

  &-unstyled {
    .time-picker-input {
      input {
        background-color: transparent;
        border: none;
      }

      .react-datepicker {
        &__triangle {
          left: 0% !important;
        }
      }
    }
  }
}
