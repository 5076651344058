@import '@assets/styles/variables.scss';

.service-cost-settings-page {
  margin: 30px auto 0;
  max-width: $width-md;
  width: 100%;

  .drive-costs {
    background-color: $color-white;
    border-radius: $border-radius-small;
    display: flex;
    flex-direction: column;
    margin: 0 0 20px;
    padding: 20px;

    &-empty {
      text-align: center;
    }

    h2 {
      font-size: 1rem;
      font-weight: 500;
      margin: 0.75rem 0 0.25rem;
    }

    ul {
      display: flex;
      gap: 1.4rem;
      list-style: none;

      li {
        min-width: 135px;

        .label {
          color: $color-grey-middle-1;
          cursor: default;
          font-size: 14px;
          line-height: 1.4;
          margin: 0 0 8px;
        }

        .value {
          align-items: center;
          background-color: $color-blue-light;
          display: flex;
          height: 36px;
          padding: 0 0.45rem;
          position: relative;

          &:after {
            content: '$';
            align-items: center;
            color: $color-grey-light-1;
            display: flex;
            font-size: 16px;
            height: 36px;
            position: absolute;
            bottom: 0;
            right: 8px;
            z-index: 0;
          }
        }
      }
    }
  }
}
