@import '@assets/styles/mixins.scss';
@import '@assets/styles/variables.scss';

.booking-list-page {
  max-width: $width-xl !important;

  .page-title {
    .btn {
      svg {
        height: 14px;
        width: 14px;
      }
    }

    .btn-export {
      @include apply-button-color($color-green-middle-1);
      height: 30px;
    }

    .field-search {
      background: transparent;
      border: 1px solid $color-white;
      border-radius: 3px;
      box-shadow: none;
      color: $color-white;
      font-size: 12px;
      font-weight: 400;
      height: 30px;
      min-width: 165px;
      outline: none;
      padding: 4px;

      &::placeholder {
        color: $color-white;
      }
    }

    .select {
      min-width: 105px;
    }
  }

  .booking-list-not-processed {
    .table {
      .hbh-table-dark {
        tr {
          th {
            &:first-child {
              width: 110px;
            }

            &:nth-child(6) {
              width: 95px;
            }

            &:nth-child(7) {
              width: 75px;
            }

            &:nth-child(8) {
              width: 50px;
            }
            &:nth-child(9),
            &:nth-child(10) {
              width: 100px;
            }

            &:nth-child(11) {
              width: 40px;
            }

            &:nth-child(12) {
              padding: 8px 2px;
              width: 125px;
            }
          }
        }
      }
    }
  }

  .booking-list-processed {
    .table {
      .hbh-table-dark {
        tr {
          th {
            &:nth-child(2) {
              width: 92px;
            }

            &:nth-child(3),
            &:nth-child(4) {
              width: 100px;
            }

            &:nth-child(5) {
              width: 80px;
            }

            &:nth-child(7) {
              width: 95px;
            }

            &:nth-child(8) {
              width: 75px;
            }

            &:nth-child(9) {
              width: 50px;
            }
            &:nth-child(10),
            &:nth-child(11) {
              width: 100px;
            }

            &:nth-child(12) {
              width: 40px;
            }

            &:nth-child(13) {
              padding: 8px 2px;
              width: 125px;
            }
          }
        }
      }
    }

    .ant-table-expanded-row {
      > td {
        border-bottom-width: 4px !important;
        padding: 0 !important;
      }

      .table-drives {
        th {
          width: auto !important;

          &:first-child {
            padding-left: 148px;
          }

          &:nth-child(3),
          &:nth-child(3) {
            width: 110px;
          }
        }

        td {
          &:first-child {
            padding-left: 148px;
            width: 240px !important;
          }

          &:nth-child(3),
          &:nth-child(7) {
            white-space: normal;
          }

          &:last-child {
            width: 150px !important;

            .col-drive-status {
              align-content: center;
              border: 1px solid $color-white;
              border-radius: 8px;
              cursor: default;
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }
  }

  .table {
    margin-bottom: 25px;

    .ant-table-cell {
      &.ant-table-row-expand-icon-cell {
        vertical-align: middle;

        .icon-expand,
        .icon-expanded {
          cursor: pointer;
          transform: scale(0.7);
          transition: transform 0.5s;

          svg {
            fill: $color-white;
          }
        }

        .icon-expanded {
          transform: rotate(180deg);
        }
      }
    }

    .hbh-table-dark {
      tr {
        th {
          padding: 16px 8px;

          &:last-child {
            width: 130px;
          }
        }

        td {
          padding: 8px;

          .attachments {
            svg {
              fill: $color-white;
              width: 20px;
            }
          }

          .type-of-trip {
            align-items: center;
            display: flex;

            svg {
              fill: $color-white;
              margin-top: 3px;
              width: 22px;
            }
          }
        }
      }
    }

    .btn {
      font-size: 12px;
      height: 24px;
      min-height: auto;
      min-width: 90px;
      position: relative;
      width: 100%;

      &:hover {
        color: $color-white;
      }

      &.btn-forward {
        @include apply-button-color($color-yellow-middle-1);
      }

      &.btn-forwarded {
        background-color: transparent;
        border-color: $color-blue-secondary;
        cursor: default;
        line-height: 0.75;
        opacity: 0.5;
        padding: 6px 2px;
        white-space: initial;
      }
    }

    .row-disabled {
      td {
        background: lighten($color-grey-dark-4, 10%) !important;
        pointer-events: none;
      }
    }
  }

  .bookings-export {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .btn-export {
      font-size: 1rem;
    }

    > p {
      color: $color-white;
      font-size: 1rem;
    }

    &-sep {
      align-items: center;
      color: $color-white;
      display: flex;
      justify-content: center;
      text-align: center;
      position: relative;
      padding: 0.5rem 1rem;
      width: 90%;

      &:before,
      &:after {
        content: '';
        border-bottom: 1px dashed $color-white;
        height: 1px;
        position: absolute;
        top: 50%;
        width: 50%;
      }

      &:before {
        right: 50%;
        margin-right: 20px;
      }

      &:after {
        left: 50%;
        margin-left: 20px;
      }
    }
  }
}
