@import '@assets/styles/variables.scss';

.btn-delete {
  svg {
    fill: $color-grey-middle-1 !important;
    transition: 0.2s fill linear;

    &:hover {
      fill: $color-blue-primary;
    }
  }
}

.charges-actions {
  color: $color-grey-middle-1;
  font-size: 10px;
  line-height: 1.4;

  .buttons {
    display: flex;

    .btn {
      font-size: 12px;
      justify-content: center;
      height: auto;
      min-height: 22px;
      padding: 0;
      width: 30px;

      &:first-child {
        margin-right: 5px;
      }
    }
  }
}
