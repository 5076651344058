@import '@assets/styles/variables.scss';
@import '@assets/styles/extends.scss';

.feedbacks {
  color: $color-white;

  &-tabs {
    .tab {
      &.active {
        border-bottom-color: $color-green-light-2;

        .badge {
          background-color: $color-green-light-2;
        }
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;

    div.feedback {
      margin-bottom: 45px;

      &-list {
        &-empty {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: 14px;
          line-height: 1.36;
          opacity: 0.5;
          height: 100%;
        }

        &-header {
          @extend .flex;
          border-bottom: 1px solid #ffffff60;
          margin-bottom: 22px;
          min-height: 32px;
          padding-bottom: 5px;
          width: 100%;
        }

        &-title {
          color: $color-grey-light-4;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: 0.44px;
        }
      }
    }
  }

  .ddo-page-title-tools {
    justify-content: space-between;

    .date-range-picker {
      min-width: 195px;
    }
  }

  .ddo-page-title {
    margin-bottom: 5px;
  }

  .pagination {
    margin-left: auto;
  }
}
