@import '@assets/styles/variables.scss';

.chip-group {
  &-label {
    &[data-disabled][data-checked] {
      border: 1px solid $color-blue-primary;
      color: $color-grey-middle-2;
      opacity: 0.8;

      span {
        color: $color-blue-primary;
      }
    }
  }
}
