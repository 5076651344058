@import '@assets/styles/variables.scss';
@import '@assets/styles/variables.scss';

.ddo-page-title {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  margin: 25px 0 15px;
  padding: 0 0 12px;

  &-line {
    border-bottom: 1px solid #ffffff60;
  }

  &-text {
    color: $color-white;
    font-size: 20px;
  }

  &-tools {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    max-width: 700px;

    & > * {
      margin-right: 15px;
    }
  }
}

@media screen and (max-width: 900px) {
  .ddo-page-title {
    align-items: flex-start;
    flex-direction: column;

    &-text {
      font-size: 16px;
      margin: 0 0 10px;
    }

    &-tools {
      margin: 0;
    }
  }
}

@media screen and (max-width: 620px) {
  .ddo-page-title {
    &-tools {
      align-items: flex-start;
      flex-direction: column;

      > * {
        margin-bottom: 10px;
      }
    }
  }
}
