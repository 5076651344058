@import '@assets/styles/variables.scss';

.nav {
  background: #0f0f0f;
  display: flex;
  gap: 0.1rem;
  justify-content: flex-start;
  height: 40px;
  width: 100%;

  &-item {
    align-items: center;
    color: $color-white;
    display: flex;
    font-size: 1rem;
    gap: 0.5rem;
    padding: 0 1rem;

    &:hover {
      background-color: $color-blue-dark-1;
      color: $color-white;
    }

    svg {
      fill: $color-white;

      &.icon {
        height: 18px;
        width: 18px;
      }
    }
  }
}
