@import '@assets/styles/variables.scss';

.tag {
  align-items: center;
  background-color: #0000004d;
  border-radius: 30px;
  display: flex;
  height: 36px;
  justify-content: center;
  padding: 0 5px 0 10px;

  p {
    color: $color-white;
    font-size: 12px;
    line-height: 16px;
    margin-right: 8px;
  }

  svg:not(:root) {
    margin: 2px;
  }

  &-cancel {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
  }
}
