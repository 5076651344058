@import '@assets/styles/variables.scss';

.input-time {
  width: 100%;

  &-label {
    display: inline-block;
  }

  input {
    border: 1px solid $theme-light-input-border;
    border-radius: $border-radius-small;
    font-size: 14px;
    height: 36px;
    padding: 0 0 0 6px;
    width: 100%;

    &:focus {
      background-color: transparent;
      border: 1px solid $color-grey-dark-2;
    }

    &:disabled {
      background-color: #9b9b9b20 !important;
      border: 1px solid #9b9b9b20 !important;
      color: $color-grey-middle-1 !important;
      cursor: default !important;
    }
  }

  input[type='time']::-webkit-datetime-edit-ampm-field {
    display: none;
  }
}
