@import '@assets/styles/variables.scss';

.form-drive-edit {
  min-width: 680px;
  padding: 10px 15px 5px 10px;

  h2 {
    color: $color-grey-dark-2;
    font-size: 24px;
    font-weight: 500;
    margin: 0 0 18px;
  }

  h3 {
    color: $color-black;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .font-red {
    color: $color-error;
  }

  .row {
    gap: 15px;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .fieldset {
    align-items: center;
    display: flex;

    &:not(:last-child) {
      margin-right: 25px;
    }

    > .field:not(:last-child) {
      margin-right: 10px;
    }

    .field-date {
      width: 105px;
    }

    .field-time {
      width: 90px;
      p {
        max-width: 90px;
      }
    }

    .field-odometer {
      width: 125px;
    }
  }

  .item {
    &:first-child {
      min-width: 360px;
    }
    .label {
      color: $color-grey-middle-1;
      font-size: 12px;
    }

    .value {
      color: $color-grey-dark-2;
      font-size: 14px;
    }
  }

  .footer {
    align-items: center;
    display: flex;

    .btn-submit {
      font-size: 14px;
      font-weight: bold;
      height: auto;
      margin-left: auto;
      min-width: 180px;
      padding: 8px 15px;
    }
  }
}
