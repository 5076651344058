// Colors
$color-black: #000;
$color-white: #fff;
$color-blue-primary: #0288ff;
$color-blue-secondary: #3a89f7;
$color-blue-dark-bg: #0a6eb4;
$color-blue-dark-1: #095d93;
$color-blue-dark-2: #075287;
$color-blue-dark-3: #091d21;
$color-blue-light: #f0f7fd;
$color-cyan: #0adbbb;
$color-green-light-1: #18d92e;
$color-green-light-2: #30ac3e;
$color-green-middle-1: #417505;
$color-green-middle-2: #207857;
$color-green-dark-1: #6a9826;
$color-green-grass: #0dac26;
$color-grey-dark-1: #3f424e;
$color-grey-dark-2: #434a4e;
$color-grey-dark-3: #1e2a36;
$color-grey-dark-4: #262829;
$color-grey-dark-5: #171717;
$color-grey-dark-6: #161616;
$color-grey-light-1: #c5d3e2;
$color-grey-light-2: #e0e8ed;
$color-grey-light-3: #b8bfc4;
$color-grey-light-4: #ccc;
$color-grey-light-5: #98a6b5;
$color-grey-light-6: #929292;
$color-grey-light-7: #808080;
$color-grey-middle-1: #6e7c8b;
$color-grey-middle-2: #333536;
$color-grey-middle-3: #2a2d34;
$color-purple-middle-1: #cc8899;
$color-red-dark-1: #b3262b;
$color-red-light-1: #ff6666;
$color-red-light-2: #f44a56;
$color-red-light-3: #f26c4f;
$color-red-middle-1: #c64454;
$color-yellow-1: #f8e71c;
$color-yellow-2: #eac810;
$color-yellow-middle-1: #f5a623;
$color-yellow-dark-1: #a58b06;
$color-turquoise-light-1: #296755;
$color-turquoise-light-2: #206f78;
$color-turquoise-light-3: #70d3f0;
$color-turquoise-light-4: #c7f2ff;

// Dark Theme
$theme-dark-bg-accent: #14181dd9;
$theme-dark-bg-accept-form: #14181ddb;
$theme-dark-color-accent: #007eec;
$theme-dark-color-blue-light-1: #5ba8ff;
$theme-dark-color-blue-light-2: #0289ff;
$theme-dark-color-green-success: #42d08c;
$theme-dark-color-green-dark-1: #076e55;
$theme-dark-color-grey-1: #f5f5f5;
$theme-dark-color-grey-2: #cdcdcd;
$theme-dark-color-grey-3: #999;
$theme-dark-color-grey-5: #85878c;
$theme-dark-color-grey-6: #66676b;
$theme-dark-color-grey-7: #3f4247;
$theme-dark-color-grey-8: #243240;
$theme-dark-color-grey-85: #1f242a;
$theme-dark-color-grey-9: #181c21;
$theme-dark-color-grey-95: #040802;
$theme-dark-color-red-alert: #ff4440;
$theme-dark-color-yellow-1: #fbb839;

// Light Theme
$theme-light-input-border: #ced4da;

// Fonts
$font-default: 'OpenSans', 'Helvetica Neue', sans-serif;

// Miscellaneous
$border-radius-small: 0.25rem;
$border-radius-md: 0.5rem;
$btn-height: 30px;
$input-height: 30px;

$border-color-light: $color-grey-light-1;
$color-error: $color-red-light-2;

$width-md: 1080px;
$width-lg: 1280px;
$width-xl: 1360px;
$width-xxl: 1400px;
$width-xxxl: 1560px;
