@import '@assets/styles/variables.scss';

$color-bg: #232323cc;

.dashboard {
  background: $color-bg;
  border-radius: 0 $border-radius-md $border-radius-md $border-radius-md;
  color: $color-grey-dark-2;
  display: flex;
  flex-direction: column;
  margin: 0 0 1rem;
  max-width: $width-lg !important;
  padding: 2rem;

  &-utilisation {
    .field-pax {
      display: none;
    }
  }

  &-period {
    color: $color-white;
    font-size: 0.75rem;
  }

  h1,
  h2,
  h3 {
    color: $color-white;
    font-weight: bold;
    margin: 0 0 1rem;
  }

  h1 {
    font-size: 1.25rem;
  }

  h2 {
    font-size: 1.1rem;
  }

  h3 {
    font-size: 1rem;
  }

  .btn {
    height: 40px;
  }

  .charts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    figure {
      box-sizing: border-box;
      background-color: $color-grey-dark-4;
      border-radius: $border-radius-small;
      color: $color-white;
      display: flex;
      flex-basis: calc(50% - 1rem);
      margin: 0 0 2rem;
      padding: 1rem;
      position: relative;

      &:has(.bar) {
        margin-bottom: auto;
      }
    }

    .chart {
      width: 100%;

      &-data-total {
        font-weight: 600;
        padding-right: 1rem;
        text-align: right;
      }
    }
  }

  .select-util-type {
    display: flex;
    flex-direction: column;
    max-width: 280px;
    margin: 0 0 2em;
  }

  .tooltip {
    align-items: center;
    cursor: default;
    display: flex;
    gap: 10px;
  }
}
