@import '@assets/styles/variables.scss';

ul.passenger-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;

  li {
    flex: 0 0 32%;
    margin-bottom: 20px;

    &:last-child {
      flex: 0 0 66%;
    }

    .label {
      color: $color-grey-middle-1;
      font-size: 12px;
      margin-bottom: 8px;
    }

    .value {
      align-items: center;
      border: 1px solid $color-grey-light-1;
      color: $color-grey-dark-2;
      font-size: 14px;
      display: flex;
      height: 30px;
      overflow: hidden;
      padding: 0 8px;
      position: relative;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
