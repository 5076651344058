@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import '@ui-modules/assets/styles/normalize.scss';
@import '@ui-modules/assets/styles/common.scss';
@import './common.scss';
@import './media.scss';
@import './mixins.scss';
@import './variables.scss';

/// TODO: remove it after the ui-modules components are replaced
@import './antd.scss';
