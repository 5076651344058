.details-vehicle-number {
  align-self: flex-end;
  display: flex;
  margin-right: 45px;
}

.details-vehicle-title {
  color: #e0e8ed;
  display: inline-block;
  font-weight: 600;
  font-size: 20px;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.details-vehicle-title-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: 13px;
}

.details-info-text-row {
  margin-left: 13px;
}
