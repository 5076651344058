@import '@assets/styles/variables.scss';
@import '@assets/styles/extends.scss';

.feedback-container {
  .feedback {
    align-items: flex-start;
    color: $color-grey-light-6;
    display: flex;
    margin-bottom: 12px;
    position: relative;
    width: 100%;

    &-created {
      @extend .flex-column;
      align-items: center;
      flex: 1 1 100px;
      justify-content: center;
      min-height: 100px;
      min-width: 100px;
      padding: 0 13px;
      width: 100px;

      &-date {
        color: $color-grey-light-6;
        font-size: 12px;
      }

      &-icon {
        height: 28px;
        margin-bottom: 12px;
        width: 28px;

        svg {
          fill: $color-grey-light-6;
        }
      }
    }

    &-rate {
      @extend .flex-column;
      flex: 1 1 155px;
      min-width: 155px;
      width: 155px;
    }

    &-details {
      background-color: #28292b;
      color: $color-grey-light-6;
      flex: 1 1 100%;
      font-size: 14px;
      max-width: 100%;
      min-height: 108px;
      position: relative;
      text-align: left;
      width: 100%;
      word-break: break-word;

      &-header {
        @extend .flex-row;
        min-height: 65px;
        padding: 10px 15px 10px 30px;
      }

      &-review {
        @extend .flex;
        align-items: center;
        flex-wrap: nowrap;
        flex: 1 1 100%;

        &-subject {
          @extend .flex-row;
          justify-content: space-between;
          list-style: none;
          margin: 0;
          padding: 0;
          text-align: left;
          width: 100%;

          li {
            @extend .flex-row;

            .icon {
              align-items: stretch;
              display: flex;
              justify-content: stretch;
              margin-right: 10px;
              max-height: 25px;
              max-width: 32px;
              position: relative;

              svg {
                fill: $color-grey-light-6 !important;
                width: 100%;
              }
            }

            .text {
              @extend .flex-column;
              justify-content: center;

              .label {
                color: $color-grey-light-6;
                line-height: 1.36;
                letter-spacing: 0.39px;
              }

              .value {
                color: $color-white;
                line-height: 1.36;
                letter-spacing: 0.39px;
              }
            }
          }
        }
      }
    }

    &-status-selector {
      margin-left: 30px;
      min-height: 30px;
      min-width: 200px;
      position: relative;

      > div {
        position: absolute;
        top: 0;
      }
    }

    &-comment {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.5s ease-in-out;

      &.open {
        max-height: 450px;
      }

      .comment {
        min-height: 21px;
        padding: 10px 30px;
      }

      .question {
        border-top: 1px solid #464849;
        display: flex;
        font-size: 14px;
        letter-spacing: 0.39px;
        line-height: 19px;
        padding: 10px 22px 10px 48px;

        &:before {
          content: '“';
          color: $color-white;
          font-size: 60px;
          line-height: 50px;
          margin-right: 17px;
        }
      }
    }

    &-answer-wrap {
      min-height: 21px;
      padding: 10px 30px;

      .feedback-answer {
        border-top: 1px solid #464849;
        display: flex;
        padding: 10px 22px 10px 48px;

        &-by {
          @extend .flex-column;
          font-size: 14px;
          letter-spacing: 0.39px;
          line-height: 1.36;
          margin-right: 65px;
        }

        &-by-prefix {
          color: $color-grey-light-6;
          font-size: 12px;
          line-height: 17px;
          text-align: left;
          white-space: nowrap;
        }

        &-by-name {
          color: $color-white;
          font-size: 12px;
          font-weight: bold;
          line-height: 17px;
          text-align: left;
          white-space: nowrap;
        }

        &-date {
          color: $color-grey-light-6;
          font-size: 12px;
          line-height: 17px;
          margin-top: 5px;
          text-align: left;
          white-space: nowrap;
        }
      }
    }

    &-footer {
      @extend .flex-row;
      background-color: #252626;
      color: $color-grey-light-6;
      justify-content: center;
      min-height: 19px;
      padding: 10px 30px;

      .feedback-id {
        width: 35%;
      }

      .comment-trigger {
        @extend .flex-row;
        justify-content: center;
        flex-grow: 1;

        .btn-trigger {
          background: none;
          border: 0;
          color: $color-white;
          cursor: pointer;
          text-align: center;

          &.enabled {
            color: $color-grey-light-6;

            .icon-caret {
              svg {
                fill: $color-grey-light-6;
                transform: rotate(180deg);
              }
            }
          }

          .icon-caret {
            padding: 0 10px;
            svg {
              fill: $color-white;
              transition: transform 180ms ease-in-out;
            }
          }
        }
      }

      .last-action {
        font-size: 12px;
        text-align: right;
        width: 35%;

        &-by {
          color: $color-white;
          font-weight: bold;
        }
      }
    }
  }
}
