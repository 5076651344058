@import '@assets/styles/mixins.scss';
@import '@assets/styles/variables.scss';

.dashboard-filters {
  display: flex;
  gap: 1rem;
  margin: 0 0 30px;
  width: 100%;

  .date-input-picker {
    &-wrapper {
      height: 40px;
    }
  }

  .dropdown {
    min-width: 8.5rem;

    .rs__control {
      height: 40px;
    }
  }

  .toolbar {
    margin-left: auto;
  }
}
