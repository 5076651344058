@import '@assets/styles/variables.scss';

$table-even-background: #343434;
$table-odd-background: #464646;
$table-border: #575757;

.checklist-tabs {
  display: flex;
  align-items: flex-end;
  max-width: 100%;
  overflow-x: auto;

  .vehicle {
    width: 308px;
    min-width: max-content;
    cursor: pointer;

    .vehicle-status {
      border-radius: 8px 8px 0px 0px;
      font-size: 11px;
      display: flex;
      padding: 2px 16px;

      &-clear {
        background-color: $color-green-dark-1;
      }

      &-issues {
        background-color: $color-yellow-middle-1;
      }

      &-critical {
        background-color: $color-red-light-1;
      }

      &-not-reported {
        background-color: $color-grey-middle-1;
        border: 1px solid $color-red-light-3;
      }

      &-not-used {
        background-color: $color-grey-middle-1;
      }

      span {
        flex: 1;
      }

      .controls {
        display: flex;
        align-items: center;

        span {
          margin-right: 8px;
        }

        svg {
          width: 12px !important;
          height: 12px !important;
          fill: $color-white;
        }
      }
    }

    .vehicle-info {
      border: 1px solid $table-border;
      background-color: $table-even-background;
      padding: 10px 15px;
      min-height: 60px;

      .main-content {
        display: flex;
        align-items: center;

        img {
          width: 75px;
          height: 37px;
          margin-right: 10px;
        }
      }

      .protection {
        font-size: 12px;
        margin-left: 20px;
      }
    }
  }
}
