@import '@assets/styles/variables.scss';

.form-unavailability {
  max-width: 800px;
  padding: 0 1rem 0.5rem;

  h2 {
    color: $color-white;
    font-size: 22px;
    font-weight: 500;
    margin: 0 0 1.8rem;
    text-align: center;
  }

  .row {
    gap: 20px;
    justify-content: space-between;
    margin-bottom: 25px;
  }

  .footer {
    align-items: center;
    display: flex;

    .buttons {
      display: flex;
      gap: 20px;
    }

    .btn-submit {
      min-width: 140px;
    }
  }

  .fieldset {
    .field {
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    .footer {
      justify-content: space-between;

      .btn-submit {
        margin-left: auto;
      }
    }
  }

  .confirm-box {
    color: $color-white;

    .column {
      flex: auto;
      text-align: center;

      .period-label {
        color: $color-grey-light-2;
        font-size: 18px;
      }

      .period-date {
        color: $color-white;
        font-size: 24px;
        font-weight: bold;
      }

      .status-exist-warning {
        background-color: rgba(163, 53, 67, 0.6);
        border-radius: 8px;
        font-size: 18px;
        padding: 10px 15px;
        margin: 0 0 20px;
      }

      .status-exist-message {
        font-size: 16px;
        margin: 0 25px;
      }
    }

    .footer {
      justify-content: center;

      .btn-back {
        font-weight: bold;
        min-width: 110px;
      }
    }
  }
}
