@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.scss';

.ddo-entry {
  align-items: center;
  display: flex;
  font-family: $font-default;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 1em 0 10em;
  position: relative;

  &-nav {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: $border-radius-small;
    position: relative;

    header {
      align-items: center;
      background-color: $color-blue-primary;
      border-radius: $border-radius-small $border-radius-small 0 0;
      color: $color-yellow-2;
      cursor: default;
      display: flex;
      font-size: 20px;
      gap: 14px;
      padding: 10px 15px;

      svg {
        fill: $color-yellow-2;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 15px;
      width: 500px;

      h2 {
        color: $color-white;
        font-size: 16px;
        margin: 0 0 16px;
      }

      fieldset {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin: 0 0 25px;

        .field {
          align-items: center;
          display: flex;
          flex-basis: calc(50% - 8px);

          > div {
            color: $color-white;
            width: 100%;
          }

          .dropdown {
            width: 100%;

            .rs__control {
              height: 34px;
            }

            .rs__input {
              cursor: default;
            }
          }
        }
      }

      .btn-go {
        margin-left: auto;
        min-height: 34px;
        min-width: 80px;
      }
    }
  }
}

.ddo-page-wrapper:has(.ddo-entry) {
  display: flex;
  flex: 1;
  flex-direction: column;
}
