@import '@assets/styles/variables.scss';

.tabs-wrap {
  display: flex;
  justify-content: space-between;
  position: relative;

  .tabs {
    display: flex;
    justify-content: flex-start;

    .tab {
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 12px;
      position: relative;
      outline: none;
      text-transform: uppercase;

      &.active {
        .badge {
          background-color: $color-blue-primary;
        }
      }

      .badge {
        background-color: $color-blue-dark-3;
        border-radius: 15px;
        display: inline-block;
        margin-left: 8px;
        padding: 1px 8px;
      }
    }
  }

  &.default {
    border-bottom: 1px solid #ffffff60;
    margin-bottom: 22px;

    .tabs {
      gap: 35px;

      .tab {
        bottom: -1px;
        color: $color-grey-light-3;
        padding: 0 0 10px;
        text-align: left;

        &.active {
          border-bottom: 2px solid $color-blue-primary;
          color: $color-white;
        }
      }
    }
  }

  &.outline {
    .tabs {
      gap: 10px;

      .tab {
        background-color: #232323cc;
        border-radius: $border-radius-md $border-radius-md 0 0;
        color: $color-grey-light-3;
        padding: 10px 20px;
        opacity: 0.75;
        text-align: center;

        &.active {
          color: $color-white;
          opacity: 1;
        }
      }
    }
  }

  &.tabs-white {
    .tabs {
      .tab {
        background-color: $color-white;
        color: $color-grey-dark-3;
        font-size: 16px;
        font-weight: 600;
        padding: 0.465rem 2.4rem;
        max-height: 2.5rem;
        text-transform: inherit;

        &.active {
          color: $color-grey-dark-3;
          opacity: 1;
        }
      }
    }
  }

  .toolbar {
    display: flex;
    position: absolute;
    bottom: 10px;
    right: 0;
  }
}
