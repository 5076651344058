@import '@assets/styles/variables.scss';

.expansion-panel {
  &-chevron-opened {
    align-items: center;
    background: $color-grey-dark-4;
    border: 1px solid $color-grey-dark-5;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    height: 32px;
    justify-content: center;
    width: 32px;
  }

  &-chevron-closed {
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    height: 32px;
    justify-content: center;
    width: 32px;
  }

  &-closed {
    cursor: pointer;
    background: $color-grey-dark-4;
    border: 1px solid $color-grey-dark-5;
    border-radius: 4px;
    padding: 14px 16px;
  }

  &-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 0.5rem;
  }

  &-title {
    color: #e0e8ed;
    font-size: 20px;
  }
}
