@import '@assets/styles/variables.scss';

main.ddo-main-layout {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  font-family: $font-default;
  position: relative;
  width: 100%;

  &.bg-color {
    background-color: $color-grey-dark-3;
  }

  &.bg-image {
    background-attachment: fixed !important;
    background-color: $color-grey-middle-2;
    background-repeat: no-repeat;
    background-size: cover !important;
    overflow-x: hidden;
  }

  .ddo-nav {
    display: flex;
    height: 40px;
    position: relative;
    z-index: 1;

    &-item {
      align-items: center;
      background-color: $color-black;
      color: $color-white;
      cursor: pointer;
      display: flex;
      font-size: 16px;
      height: 100%;
      margin-left: 18px;
      padding: 0 12px;

      &-icon {
        padding: 0 18px;

        svg {
          fill: $color-white;
          height: 22px;
          width: 22px;
        }
      }

      &:last-child {
        background-color: $color-blue-secondary;
        cursor: default;
        pointer-events: none;
      }
    }
  }

  .ddo-page-wrapper {
    margin: 0 auto 40px;
    padding: 0;
    width: 100%;

    .hbh-main-menu-container {
      .hbh-menu-panel {
        margin: 150px 0 0;
        min-width: 500px;

        svg {
          fill: $color-white;
        }

        @media screen and (max-width: 620px) {
          margin-top: 40px;
          max-width: 360px;
          min-width: auto;

          .hbh-menu-panel-header {
            .logo {
              display: none;
            }

            .title {
              font-size: 16px;
              margin: 0;
              text-align: center;
            }
          }

          .hbh-menu-panel-content {
            align-items: center;
            flex-direction: column;

            .hbh-menu-button {
              margin-right: 0;
              min-width: 300px;
            }

            .hbh-submenu-container {
              align-items: center;
              flex-direction: column;
            }
          }
        }
      }
    }

    .hbh-container {
      font-family: $font-default;
      max-width: $width-lg;
      width: 100%;

      .ddo-page-title-tools {
        .hbh-select-label {
          color: $color-white;
          margin: 0 0 8px;
        }
      }
    }

    .hbh-table {
      td {
        vertical-align: middle;
      }
    }

    .hbh-table-dark,
    .hbh-table-light {
      .ant-table-content {
        .ant-table-tbody td:has(span.tooltip) {
          overflow: inherit;
        }
      }
    }
  }
}
