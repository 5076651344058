@import '@assets/styles/variables.scss';

.inp-time {
  width: 100%;

  &-label {
    color: $color-grey-middle-1;
    cursor: pointer;
    display: block;
    font-size: 12px;
    line-height: 1.4;
    margin-bottom: 10px;
  }

  input {
    border: 1px solid $color-grey-light-5;
    border-radius: $border-radius-small;
    font-size: 14px;
    height: 38px;
    padding: 0 0 0 6px;
    width: 100%;

    &:focus {
      background-color: transparent;
      border: 1px solid $color-grey-dark-2;
    }

    &:disabled {
      background-color: #9b9b9b20 !important;
      border: 1px solid #9b9b9b20 !important;
      color: $color-grey-middle-1 !important;
      cursor: default !important;
    }
  }

  input[type='time']::-webkit-datetime-edit-ampm-field {
    display: none;
  }

  &-error {
    color: $color-error;
    font-size: 9px;
    margin: 0;
    position: absolute;
  }
}
