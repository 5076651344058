@import '@assets/styles/mixins.scss';
@import '@assets/styles/variables.scss';

.btn-cost-recovery-report {
  @include apply-button-color($color-blue-primary);
  flex-basis: 100% !important;
}

.btn-create-invoice {
  @include apply-button-color($color-green-middle-1);
}

.btn-invoice-history {
  @include apply-button-color($color-green-middle-2);
}

.btn-service-cost {
  @include apply-button-color($color-green-middle-2);
  flex-basis: 100% !important;
}
