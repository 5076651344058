@import '@assets/styles/variables.scss';

$color-bg: #232323cc;

.figures {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  margin: 0 0 2rem;

  > div {
    background: $color-bg;
    border-radius: $border-radius-md;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 240px;
    padding: 1rem;

    h2 {
      font-size: 1rem;
      margin: 0 0 0.5rem;
    }

    p {
      align-items: center;
      color: $color-white;
      display: flex;
      font-size: 2rem;
      font-weight: bold;
    }

    .icon {
      display: inline-block;
      margin-right: 1.5rem;
      height: 48px;
      width: 48px;

      svg {
        fill: $theme-dark-color-grey-7;
        height: auto;
        width: 100%;
      }
    }

    &:nth-child(4) {
      .icon {
        height: auto;
        width: 42px;

        svg {
          fill: $theme-dark-color-grey-7;
          height: auto;
          width: 100%;
        }
      }
    }
  }
}
