@import '@assets/styles/variables.scss';

.checkbox {
  &-label {
    cursor: pointer;
  }
}

.theme-dark {
  .checkbox {
    &-label {
      color: $color-white;
    }
  }
}
