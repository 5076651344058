@import '@assets/styles/variables.scss';
@import '@assets/styles/extends.scss';

.surface-panel {
  animation: slideUp 0.5s linear;
  background-color: #000000b3;
  border-radius: 20px;
  padding: 30px 35px 35px;

  &-title {
    color: $color-white;
    font-size: 20px;
    margin-bottom: 30px;
    text-align: center;
  }
}
