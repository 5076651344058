@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.scss';
@import '@assets/styles/extends.scss';

.flex {
  display: flex;
  justify-content: space-between;
}

.agency-invoice-charges-container {
  color: $color-grey-dark-2;
  display: flex;
  flex-direction: column;

  .row {
    @extend .flex;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  .table {
    @include no-items-table-style;
    @extend .invoice-table;

    table {
      border-spacing: 4px;
      margin: 8px -4px 10px;
      table-layout: fixed;
      white-space: nowrap;

      thead {
        tr {
          th {
            &:first-child {
              width: 10%;
            }
            &:nth-child(2) {
              width: 50%;
            }
            &:nth-child(3) {
              width: 14%;
            }
            &:nth-child(4) {
              width: 20%;
            }
            &:last-child {
              width: 65px;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            overflow: hidden;

            &:last-child {
              border: 0;
              padding: 0;
              width: 65px;
            }
          }
        }
      }
    }
  }

  .form {
    border-spacing: 4px;
    display: table;
    margin: 0 -4px 12px;
    table-layout: fixed;
    white-space: nowrap;

    &-row {
      display: table-row;
    }

    &-cell {
      display: table-cell;
      vertical-align: top;
      position: relative;

      input + small[role='alert'] {
        position: absolute;
        bottom: -15px;
        left: 0;
      }

      &:first-child {
        width: 9.9%;
      }
      &:nth-child(2) {
        width: 49%;
      }
      &:nth-child(3) {
        width: 13.8%;
      }
      &:last-child {
        width: 20%;
      }
    }

    &-actions {
      display: flex;
      position: relative;
      width: 70px;

      .btn {
        min-height: auto;
        padding: 2px 8px;

        &-confirm {
          padding: 2px 8px;
        }

        &-delete {
          svg {
            fill: $color-grey-middle-1;
            transition: 0.2s fill linear;

            &:hover {
              fill: $color-blue-primary;
            }
          }
        }
      }
    }
  }
}
