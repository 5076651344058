@import '@assets/styles/variables.scss';

.export-range {
  display: flex;
  flex-direction: column;
  width: 100%;

  .date-input-picker {
    align-self: center;
    display: flex;

    &-input {
      min-width: 280px;

      > span {
        color: $color-white;
      }
    }
  }

  .footer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 2rem 0 0;

    .btn {
      min-width: 140px;
    }
  }
}
