@import '@assets/styles/variables.scss';
@import '@assets/styles/extends.scss';

.travel-request-trip-edit-page {
  max-width: 768px !important;
  margin-top: 5% !important;

  .form-panel {
    animation: slideUp 0.5s linear;
    background-color: #000000b3;
    border-radius: 20px;
    padding: 30px 35px 35px;

    &-title {
      color: $color-white;
      font-size: 20px;
      margin-bottom: 30px;
      text-align: center;
    }

    .form-trip-edit {
      display: flex;
      flex-direction: column;
      gap: 20px;

      h3 {
        color: $color-white;
        font-size: 18px;
        margin: 15px 0 0;
      }

      .fieldset {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        width: 100%;

        .field {
          flex: calc(50% - 20px);
          max-width: calc(50% - 10px);

          label {
            color: $color-white !important;
            font-size: 16px !important;
          }

          .hbh-date,
          .hbh-text-field {
            border-radius: 10px;
            font-size: 14px;
            height: 38px;

            &:disabled {
              background-color: $color-grey-light-1;
              border: 1px solid $color-grey-light-1;
              opacity: 1;
            }
          }

          .hbh-select {
            .rs__control {
              border-radius: 10px;
              font-size: 14px;
              height: 38px;
            }

            .rs__indicator svg {
              fill: $color-grey-light-5;
            }
          }

          &-switcher {
            align-items: center;
            display: flex;
            gap: 20px;
            margin-top: 32px;
          }
        }
      }

      .footer {
        display: flex;
        justify-content: space-between;

        .btn {
          font-weight: bold;
          height: 38px;
          min-width: 160px;
        }
      }

      .terms {
        span {
          color: $color-white;
        }
      }
    }
  }

  .travel-request-submitted {
    animation: slideUp 0.5s linear;
    background-color: #000000b3;
    border-radius: 20px;
    color: $color-white;
    font-size: 18px;
    padding: 30px 35px;
    text-align: center;
  }
}
