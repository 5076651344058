@import '@assets/styles/mixins.scss';
@import '@assets/styles/variables.scss';

section.bp.theme-dark {
  background-color: $theme-dark-bg-accept-form;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: $border-radius-small;
  margin: 0 0 2rem;
  padding: 1.25rem;
  position: relative;
  z-index: 9;

  .btn {
    &-booking-reject {
      color: $theme-dark-color-red-alert;
      position: absolute;
      right: 9rem;
      top: 1.55rem;
    }
  }

  .bp-info {
    align-items: flex-start;
    color: $color-white;
    display: flex;
    flex-wrap: nowrap;
    gap: 0.75rem;
    margin: 0 0 1rem;

    &-title {
      h2 {
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 2.125rem;
      }

      span {
        color: $theme-dark-color-blue-light-1;
        display: inline-block;
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.5rem;
      }
    }

    &-btn-back {
      padding: 0.25rem 0 0;
    }

    &-details {
      color: $theme-dark-color-blue-light-1;
      display: flex;
      font-weight: 500;
      line-height: 0;
      margin: 0.25rem 0 0 auto;

      svg {
        margin-right: 10px;
      }

      &:hover {
        color: $theme-dark-color-blue-light-1;

        svg {
          fill: $theme-dark-color-blue-light-1;
        }
      }
    }

    svg {
      fill: $color-white;
    }
  }

  .bp-leg {
    display: flex;
    flex-direction: column;
    margin: 0 0 5px;

    &-title {
      align-items: flex-start;
      color: $theme-dark-color-grey-3;
      cursor: default;
      display: flex;
      font-size: 0.875rem;
      line-height: 1.375rem;
      gap: 0.85rem;
      margin: 0 0 1.25rem;
      padding-left: 1.875rem;

      &:not(:first-child) {
        margin-top: 1.75rem;
      }

      h3 {
        color: $color-white;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;

        span {
          display: inline-block;
          color: $theme-dark-color-blue-light-1;
          font-weight: 600;
          margin: 0 0.1rem;
        }
      }

      svg {
        position: relative;
        top: 0.4rem;
      }
    }

    &-form {
      align-items: flex-end;
      display: flex;
      flex: 1;
      gap: 1rem;
      padding-left: 1.875rem;
      width: 100%;

      &-fieldset {
        flex: 1;
        margin: 0;

        @media screen and (max-width: 900px) {
          align-items: flex-start;
          flex-direction: column;
          margin: 0 0 20px;
          width: 100%;
        }

        &-row {
          display: flex;
          flex-wrap: wrap;
          gap: 1.25rem;
          margin: 0;

          &:first-child {
            margin: 0 0 1.4rem;

            .field {
              &:nth-child(5) {
                flex: 1 1 125px;
              }

              &:nth-child(6) {
                flex: 1 1 100px;
              }
            }
          }

          .field {
            flex: 1;

            label {
              color: $color-white;
              font-size: 0.875rem;
              font-weight: 400;
              line-height: 1.375rem;
              margin: 0 0 0.1rem;

              span {
                display: none;
              }
            }

            svg {
              fill: $color-grey-dark-2;
            }

            .date-picker-input,
            .dropdown-input {
              height: 32px;
            }

            .textarea {
              height: 44px;
            }

            .date-picker-input,
            .dropdown-input,
            .textarea {
              color: $color-white;
              font-size: 1rem;
              padding: 4px 0;

              &[disabled] {
                background: none;
              }

              &:placeholder-shown {
                border-bottom: 1px solid $theme-dark-color-grey-3;
                font-size: 14px;
              }
            }

            .time-picker {
              &-label {
                display: flex;
              }

              &-input {
                height: 32px;

                input {
                  color: $color-white;
                  font-size: 1rem;
                  height: 32px;
                  min-height: auto;
                  padding: 4px 0;

                  &:required[value=''] {
                    border-bottom: 1px solid $theme-dark-color-grey-3;
                    border-radius: 0;
                  }

                  &:required:not([value='']) {
                    border-bottom: none;
                  }
                }

                &:has(input[disabled]) {
                  cursor: not-allowed;
                  opacity: 0.6;
                  pointer-events: none;
                }
              }
            }

            textarea {
              height: 44px;
              padding: 0;

              &.disabled {
                opacity: 0.5;
                pointer-events: none;
              }
            }
          }

          &.section-estimated {
            align-items: center;
            border: 1px dashed $color-grey-light-1;
            padding: 1rem;
            margin: 0 0 1.4rem !important;
            max-width: 400px;
          }
        }
      }

      &-ctrl {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        margin: 0 0 12px;
        padding: 0 0 12px;
        position: relative;
        width: 250px;

        p {
          color: $color-white;
          font-size: 12px;
          margin: 0 0 12px;
        }

        .link-google-map {
          display: inline-block;
          position: absolute;
          top: -4rem;
          right: 0;
        }
      }

      &-buttons {
        align-items: center;
        display: flex;
        gap: 10px;

        .btn {
          height: 30px;
          min-width: 90px;

          &-reject {
            @include apply-button-color($color-red-dark-1);
          }

          &-submit {
            min-width: 140px;
            white-space: nowrap;
          }
        }
      }

      &-rejecting {
        width: 76%;
      }

      &-rejected {
        &-status {
          color: $theme-dark-color-red-alert;
        }

        .bp-leg-form {
          &-fieldset {
            opacity: 0.5;
            pointer-events: none;
          }

          &-ctrl {
            align-items: center;
            min-height: 5rem;
          }
        }
      }

      &-footer {
        display: flex;
        justify-content: space-between;
        padding: 10px 260px 0 0;

        .btn {
          height: 30px;
          margin-left: auto;
          min-width: 150px;

          &-confirm {
            @include apply-button-color($theme-dark-color-green-dark-1);
          }

          &-verify {
            @include apply-button-color($color-yellow-middle-1);
          }
        }

        .link {
          color: $color-grey-light-1;
          transition: 0.2s all linear;

          svg {
            fill: $color-grey-light-1;
            margin-left: 10px;
            position: relative;
            top: -2px;
          }

          &:hover {
            color: $color-white;

            svg {
              fill: $color-white;
            }
          }
        }
      }

      &-edit-drive {
        flex-direction: column;

        &-item {
          align-items: flex-end;
          display: flex;
          gap: 10px;
          width: 100%;
        }
      }
    }

    &-form-reject {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
      position: absolute;
      bottom: 1.35rem;
      right: 1.25rem;
      width: 250px;

      h3 {
        font-size: 0.875rem;
        color: $color-white;
      }

      footer {
        display: flex;
        gap: 2rem;
        justify-content: space-between;
        width: 100%;

        .btn {
          height: 32px;
          width: 100%;

          &-confirm {
            @include apply-button-color($color-red-dark-1);
          }

          svg {
            height: 1rem;
            width: 1rem;
          }
        }
      }

      .field-reason {
        resize: none;
        width: 100%;

        .textarea {
          color: $color-white;
        }
      }
    }
  }
}
