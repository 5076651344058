.flex {
  display: flex;
  justify-content: space-between;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  align-items: center;
  display: flex;
}

.flex-col-center {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-ellipsis {
  display: inline-block;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.invoice-table {
  table {
    border-collapse: initial !important;

    tbody tr td {
      padding: 6px !important;
      border: solid 1px #c5d3e2;
      background-color: #fff !important;
      font-size: 12px !important;
      line-height: 1.4;
      margin-right: 10px;
      cursor: default;

      &:last-child {
        border: 0;
        padding: 0 !important;
        margin-right: 0;
        width: 65px;
      }
    }
  }
}

@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(150px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(150px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
