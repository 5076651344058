@import '@assets/styles/mixins.scss';

.btn-logbook {
  @include apply-button-color(#204378);
}

.btn-checklist {
  @include apply-button-color(#3466ac);
}

.btn-my-drivers {
  @include apply-button-color(#206f78);
}

.btn-my-vehicles {
  @include apply-button-color(#207857);
}
