.chatbot {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10;
  transition: bottom 0.2s linear;

  &-iframe {
    opacity: 0;
    border: none;
  }

  &-wrap {
    background: #0b3d5e;
    border-radius: 15px;
    height: 0;
    overflow: hidden;
    transform: translate(-26px, 26px);
    width: 0;
  }

  &.closed {
    &.animate {
      .chatbot-iframe {
        animation: chatbot-close 0.5s linear forwards;
      }
      .chatbot-wrap {
        animation: chatbot-wrap-close 0.5s cubic-bezier(0, 0, 0.14, 1.02) forwards;
      }
    }
    .btn-wrap {
      animation: button-open 0.5s cubic-bezier(0, 0, 0.14, 1.02) 0.5s forwards;
    }
    .icon-slider {
      left: -54px;
    }
  }

  &.opened {
    .btn-wrap {
      animation: button-close 0.5s cubic-bezier(0, 0, 0.14, 1.02) forwards;
    }
    .chatbot-iframe {
      animation: chatbot-open 0.5s linear 1s forwards;
    }
    .chatbot-wrap {
      animation: chatbot-wrap-open 0.5s cubic-bezier(0, 0, 0.14, 1.02) 0.5s forwards;
    }
  }

  .btn {
    align-items: center;
    background: #0b3d5e;
    border: none;
    color: white;
    cursor: pointer;
    display: flex;
    font-weight: 700;
    height: 54px;
    justify-content: space-between;
    padding: 0 0 0 1em;
    white-space: nowrap;
    width: 180px;
  }

  .btn-wrap {
    border-radius: 54px;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    width: 54px;
  }

  .icon-wrap {
    align-items: center;
    display: flex;
    height: 54px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 54px;

    svg {
      height: 40px;
      width: 40px;
    }
  }

  .icon-slider {
    align-items: center;
    display: flex;
    gap: 14px;
    height: 100%;
    padding: 0 7px;
    position: absolute;
    left: 0;
    top: 0;
    transition: left 0.5s cubic-bezier(0, 0, 0.14, 1.02);
  }
}

@keyframes button-close {
  0% {
    width: 180px;
    border-radius: 15px;
  }
  80% {
    border-radius: 25px;
  }
  100% {
    width: 54px;
    border-radius: 54px;
  }
}

@keyframes button-open {
  from {
    width: 54px;
    border-radius: 54px;
  }
  to {
    width: 180px;
    border-radius: 15px;
  }
}

@keyframes chatbot-wrap-close {
  from {
    height: min(570px, calc(100vh - 200px));
    width: 450px;
    transform: translate(0, -30px);
  }
  to {
    height: 0;
    width: 0;
    transform: translate(-27px, 27px);
  }
}

@keyframes chatbot-wrap-open {
  from {
    height: 0;
    width: 0;
    transform: translate(-27px, 27px);
  }
  to {
    height: min(570px, calc(100vh - 200px));
    width: 450px;
    transform: translate(0, -30px);
  }
}

@keyframes chatbot-close {
  to {
    opacity: 0;
  }
}

@keyframes chatbot-open {
  to {
    opacity: 1;
  }
}
