@import '@assets/styles/variables.scss';

.theme-dark.dropdown {
  .dropdown-field {
    background-color: $theme-dark-color-grey-9;
    border: 1px solid $color-grey-dark-5;
    border-radius: $border-radius-small;

    &-ctrl {
      display: none;
    }

    &-input {
      color: $theme-dark-color-grey-1;
      font-size: 1rem;
      padding: 0;

      &::placeholder {
        color: $theme-dark-color-grey-1;
      }

      &[data-disabled] {
        background: transparent;
      }

      &[data-with-icon] {
        padding-left: 2.2rem;
      }
    }

    &-icon {
      left: 8px;
      width: auto;
    }

    &-item {
      align-items: center;
      color: $color-white;
      display: flex;

      &[data-hovered] {
        background-color: lighten($theme-dark-color-accent, 20%);
      }

      svg {
        margin-right: 0.5rem;
      }
    }

    &-list {
      background: $theme-dark-color-grey-9;
      border: 1px solid $theme-dark-color-grey-7;
      border-radius: $border-radius-md;
      box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.16);
    }
  }
}
