@import '@assets/styles/variables.scss';

.average-rating {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 !important;

  &.brief {
    border-radius: 6px;
    box-sizing: border-box;
    padding: 10px 12px;

    .average-rating-header {
      flex-direction: row;

      &-facility {
        align-items: flex-start;
        flex-direction: column;
        margin-right: 20px;
        min-width: 160px;
      }
    }

    .rating-box {
      display: flex;
      flex: 1 1 88px;
      max-width: 88px;
      width: 88px;
    }
  }

  &.full {
    box-sizing: border-box;
    min-height: 315px;
    padding: 10px 25px;
    width: 210px;

    .average-rating-header {
      border-top: 1px solid #ffffff60;
      flex-direction: column-reverse;
      margin-top: 10px;

      &-facility {
        align-items: center;
        flex-direction: column-reverse;

        .sub-title {
          margin: 0 0 8px;
        }
      }

      .rating-box {
        margin-top: 10px;
      }
    }
  }

  &-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    &-facility {
      display: flex;

      .sub-title {
        color: #fff;
        display: flex;
        flex-direction: column;
        font-family: $font-default;
        font-size: 12px;
        justify-content: center;
        line-height: 19px;
        letter-spacing: 0.39px;
        opacity: 0.55;
      }
    }

    &-title {
      align-items: center;
      color: $color-white;
      display: flex;
      flex-direction: row;
      font-family: $font-default;
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 0.39px;
      margin: 0 0 8px;
      text-align: center;

      .icon {
        margin-right: 10px;
        max-height: 22px;
        max-width: 24px;
        opacity: 0.7;

        svg {
          fill: $color-white !important;
          height: 22px;
          width: 24px;
        }
      }
    }
  }
}
