@import '@assets/styles/variables.scss';

.agency-invoice-page {
  align-self: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: $font-default;
  max-width: $width-lg;
  width: 100%;
  margin: 0 auto;

  > div.header {
    border-bottom: 1px solid $color-white;
    display: flex;
    flex-direction: row;
    padding: 15px 0;
    margin: 20px 0 25px;
    max-height: 60px;

    .title {
      color: $color-white;
      font-size: 24px;
      font-weight: normal;
      line-height: 1.37;
      margin: 0;
    }
  }

  > .row {
    display: flex;
    justify-content: space-between;
    margin: 0 0 20px;

    .form-panel {
      margin: 0;
      width: 100%;
    }

    .col-left {
      margin: 0;
      width: 50%;
    }

    .col-right {
      margin: 0 0 0 20px;
      width: 50%;
    }

    .comments {
      padding: 0 20px 0 10px;
    }

    .comments,
    .send-copy {
      min-height: 160px;
    }
  }

  .balance {
    display: flex;
    justify-content: flex-end;
    margin: 0 0 20px;
  }

  .submit-row {
    flex-direction: row-reverse;
  }
}

.agency-invoice-modal {
  text-align: center;

  .modal-greeting {
    margin: 0 10px 25px;
  }

  .modal-title {
    font-weight: 900;
    margin: 0 0 10px;
  }

  .modal-sub-text {
    font-size: 16px;
    font-style: italic;
    margin: 10px 0 10px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin: 30px 0 0;
  }

  .button {
    margin: 20px auto 0;
  }

  .icon {
    margin-top: 20px;
  }
}
