@import '@assets/styles/variables.scss';

$table-even-background: #343434;
$table-odd-background: #464646;
$table-border: #575757;
$darken-background: rgba(0, 0, 0, 0.6);
$first-column-width: 275px;

@mixin stretch-columns {
  td:nth-of-type(1) {
    width: $first-column-width;
  }

  td:nth-of-type(4) {
    width: 120px;
  }

  td:nth-of-type(3),
  td:nth-of-type(5) {
    width: 300px;
  }
}

.checklist-details-page {
  max-width: $width-xxl !important;

  .header {
    margin-top: 30px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    color: $color-white;

    .title {
      font-size: 24px;
      font-weight: 300;
      margin-right: 26px;
      color: inherit;

      span {
        font-weight: 500;
      }
    }

    h3 {
      font-size: 18px;
      font-weight: 300;
      color: inherit;
    }
  }

  .table-checklist {
    border-collapse: collapse;
    font-size: 14px;
    min-width: 400px;
    border-radius: 0px 3px 3px 3px;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;

    thead tr {
      background-color: $table-even-background;
      text-align: left;

      th {
        font-size: 12px;
        font-weight: 400;
        @include stretch-columns;
      }
    }

    th,
    td {
      border: 1px solid $table-border;
    }

    td {
      padding: 18px 16px;

      img {
        display: block;
        max-width: 140px;
        height: 90px;
        border-radius: 3px;

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }

    th {
      padding: 9px 16px;
    }

    tbody tr {
      @include stretch-columns;

      &:nth-child(odd) {
        background-color: $table-odd-background;
      }

      &:nth-child(even) {
        background-color: $table-even-background;
      }
    }
  }

  .critical-icon {
    vertical-align: top;
    margin-right: 5px;
  }

  .icons-container {
    display: flex;

    .icons-divider {
      width: 16px;
    }
  }
}
