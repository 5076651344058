@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.scss';

.facility-menu-page {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin: 40px auto 5%;

  .facility-menu {
    max-width: 520px;
    position: relative;

    .btn-booking {
      @include apply-button-color(#0288ff);
    }

    .btn-mobility-planner {
      @include apply-button-color(#417505);
    }

    .btn-my-mobility {
      @include apply-button-color(#204378);
    }

    .btn-financials {
      @include apply-button-color(#0e603d);
    }

    .btn-reporting-area {
      @include apply-button-color(#5c1f2e);
    }

    .btn-customer-satisfaction {
      @include apply-button-color(#a58b06);
    }

    .btn-fleet-management {
      @include apply-button-color(#9b5122);
    }

    .btn-vehicle-tracking {
      @include apply-button-color(#006c82);
    }

    .btn-manuals {
      @include apply-button-color(#388b73);
    }

    .btn-trainings {
      @include apply-button-color(#2e4d46);
    }

    .btn-documents {
      @include apply-button-color(#416d63);
    }

    .facility-average-rating {
      @media screen and (max-width: 920px) {
        display: flex;
        justify-content: center;
        padding: 20px 0 0;
      }

      @media screen and (min-width: 920px) {
        position: absolute;
        top: 0;
        transform: translateX(-100%);
      }
    }
  }
}

.ddo-page-wrapper:has(.facility-menu-page) {
  display: flex;
  flex: 1;
  flex-direction: column;

  .hbh-main-menu-container {
    .hbh-menu-panel {
      margin: 0 !important;
    }
  }
}
