@import '@assets/styles/variables.scss';

.maintenance-banner {
  align-items: center;
  background: #689e32;
  color: $color-white;
  display: flex;
  gap: 2rem;
  padding: 0.75rem 1.8rem;
  width: 100%;

  &-msg {
    font-size: 16px;
    font-weight: 500;
    max-width: 980px;
  }

  img {
    height: 33px;
    width: 35px;
  }

  .ddo-btn {
    margin-left: auto;
  }
}
