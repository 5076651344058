@import '@assets/styles/variables.scss';

.logbook-filters-section {
  background-color: $color-grey-dark-2;
  border: 1px solid $color-grey-dark-4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 30px;
  padding: 16px;

  select {
    background-color: $color-grey-dark-4;
    background-image: linear-gradient(45deg, transparent 50%, #fff 0),
      linear-gradient(135deg, #fff 50%, transparent 0);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px),
      calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    border: 1px solid $color-grey-dark-5;
    border-radius: 4px;
    box-sizing: border-box;
    color: $color-white;
    display: inline-block;
    font: inherit;
    line-height: inherit;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;
    margin: 0;
    appearance: none;
  }

  .logbook-filters-aux {
    display: flex;
    gap: 1rem;
    margin: 1rem 0 0;

    .field {
      label {
        color: $color-white;
        margin: 0 0 0.2rem;
      }

      input {
        background-color: $color-grey-dark-4;
        border: 1px solid $color-grey-dark-5;
        color: $color-white;
      }
    }
  }

  .filter-container {
    display: flex;
    justify-content: space-between;

    .filters {
      display: flex;
      flex-wrap: wrap;

      .filter {
        margin-right: 24px;
        margin-bottom: 8px;
      }
    }

    .btn-clear {
      background: none;
      border: none;
      color: #70ddff;
      cursor: pointer;
      display: flex;
      font-size: 16px;
      font-weight: 500;
      white-space: nowrap;
    }
  }

  .empty-tags-container {
    display: flex;
    align-items: center;

    .empty-tags-label {
      color: #e0e8ed;
      font-size: 16px;
      margin-left: 10px;
    }
  }

  .tags-container {
    display: flex;
    gap: 10px;
  }
}
