@import '@assets/styles/variables.scss';

.review-answer {
  border-radius: $border-radius-small;
  min-width: 580px;
  overflow: hidden;
  text-align: left;

  &-title {
    align-items: center;
    color: $color-grey-dark-2;
    display: flex;
    font-size: 24px;
    margin: 0 0 15px;
  }

  &-content {
    font-size: 14px;
  }

  &-feedback {
    margin-bottom: 20px;
    width: 100%;

    &-title {
      color: #929292;
      font-size: 14px;
      font-style: italic;
      margin-bottom: 9px;
      padding: 0 18px;
    }

    &-text {
      color: $color-black;
      font-size: 16px;
      padding: 0 18px;
    }
  }

  &-form {
    margin-bottom: 15px;

    .field {
      margin-bottom: 15px;
    }

    .btn-confirm {
      margin-left: auto;
    }
  }
}
