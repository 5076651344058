@import '@assets/styles/mixins.scss';
@import '@assets/styles/variables.scss';

.country-report {
  max-width: $width-xxl !important;

  &-container {
    background-color: $color-white;
    border-radius: $border-radius-md;
    color: $color-grey-dark-2;
    display: flex;
    flex-direction: column;
    margin: 0 0 36px;
    padding: 30px 0 0;

    .btn-export {
      @include apply-button-color($color-green-middle-1);
    }
  }

  &-form {
    display: flex;
    margin: 0 0 30px;
    padding: 0 25px;
    width: 100%;

    .filters {
      align-items: flex-end;
      display: flex;
      gap: 0.75rem;
      max-width: 400px;

      .month {
        width: 40%;
      }

      .year {
        width: 25%;
      }

      .btn-calc-report {
        @include apply-button-color($color-blue-primary);
        width: 30%;
      }
    }
  }

  &-table {
    margin-bottom: 25px;

    .hbh-table-light {
      thead {
        tr {
          th {
            font-weight: 600;

            &:first-child {
              padding-left: 20px;
            }

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(10) {
              width: 50px;
            }

            &:nth-child(6) {
              width: 95px;
            }

            &:nth-child(8),
            &:nth-child(11),
            &:nth-child(13),
            &:nth-child(14),
            &:nth-child(15),
            &:nth-child(16) {
              width: 70px;
            }

            &:nth-child(9),
            &:nth-child(12) {
              width: 65px;
            }

            &:nth-child(5),
            &:nth-child(17) {
              width: 80px;
            }

            &:last-child {
              padding-right: 20px;
              width: 60px;
            }
          }
        }
      }

      tbody {
        tr {
          &:nth-child(odd) {
            background-color: rgba(152, 166, 181, 0.1);
          }

          &:first-child {
            background-color: $color-cyan !important;

            td {
              font-weight: bold;
            }
          }
        }
      }
    }

    @media screen and (max-width: 1260px) {
      overflow-x: scroll;

      > div {
        min-width: 1260px;
      }
    }
  }

  .pagination {
    padding: 0 25px 20px;
  }
}
