@import '@assets/styles/mixins.scss';
@import '@assets/styles/variables.scss';

.ddo-btn {
  align-items: center;
  border-radius: $border-radius-small;
  color: $color-white;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  justify-content: center;
  min-height: $btn-height;
  outline: 0;
  padding: 6px 12px;
  transition: 0.2s all linear;

  svg {
    fill: $color-white;
  }

  &[data-disabled] {
    color: $color-white;
    opacity: 0.55;
  }

  &:hover(:not(&.ddo-btn-link)) {
    color: $color-white;
    transform: scale(1.03);
  }

  &-confirm {
    @include apply-button-color($theme-dark-color-green-dark-1);
  }

  &-danger {
    @include apply-button-color($color-red-dark-1);
  }

  &-disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &-filled,
  &-primary {
    @include apply-button-color($color-blue-primary);
  }

  &-icon {
    background: none;
    border: 0;
    height: auto;
    padding: 0;

    &:hover,
    &[data-disabled] {
      background: none;
      border: none;
    }
  }

  &-link {
    background: none;
    border: 0;
    height: initial;
    min-height: auto;
    padding: 0 0.25rem;

    &:hover {
      background: none;
      color: $color-blue-primary;
    }
  }

  &-outline {
    background: none;
    border-color: $color-white;
    color: $color-white !important;

    &:hover {
      background: none;
    }
  }

  &-secondary {
    background: none;
    border-color: $color-grey-middle-3;
    color: $color-grey-middle-3;

    svg {
      fill: $color-grey-middle-3;
    }

    &:hover {
      @include apply-button-color($color-blue-primary);
      color: $color-white;

      svg {
        fill: $color-white;
      }
    }
  }

  &-submit {
    @include apply-button-color($color-green-grass);
  }

  &-transparent {
    background: none;
    border: none;
    color: $color-white;
    height: $btn-height;
    padding: 0.25rem;

    &:hover,
    &[data-disabled] {
      background: none;
      border: none;
    }

    span {
      gap: 0.5rem;
    }
  }

  &-warning {
    @include apply-button-color($color-yellow-middle-1);
  }

  .icon-only {
    margin: 0;
  }

  &.outline {
    border: 1px solid $color-white;
  }
}

a.ddo-btn {
  &-link {
    color: $color-blue-primary;
  }
}
