@import '@assets/styles/variables.scss';

.resources-page {
  &.hbh-main-menu-container {
    .hbh-menu-panel {
      margin-top: 20px !important;
      width: 600px;

      .logo {
        img {
          height: auto;
          width: 40px;
        }
      }

      .resource {
        display: flex;
        width: 100%;

        &:not(:last-child) {
          border-bottom: 1px solid $color-grey-light-1;
          margin-bottom: 20px;
          padding-bottom: 10px;
        }

        &-empty {
          color: $color-white;
        }

        .icon {
          height: 40px;
          margin-right: 15px;
          width: 40px;
        }
      }
    }
  }
}
