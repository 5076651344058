@import '@assets/styles/variables.scss';

header.ddo-header {
  align-items: center;
  background-color: $color-blue-dark-bg;
  display: flex;
  height: 80px;
  justify-content: space-between;
  width: 100%;

  &-modest {
    height: 60px;

    .ddo-header-user-status {
      .btn-logout {
        margin: 0.1rem 0 0;
      }
    }
  }

  &.digital-office {
    background-color: $color-blue-secondary;

    .ddo-header-language {
      background-color: lighten($color-blue-secondary, 8%);
    }

    .ddo-header-user-status {
      background-color: $color-blue-dark-bg;
    }
  }
}

.ddo-header {
  &-logo {
    cursor: pointer;
    margin-left: 5%;
  }

  &-language {
    background-color: #0293d6;
    border-radius: 22px;
    margin: 5px 10px;
    padding: 5px 20px;
    text-align: center;

    p {
      color: $color-white;
    }

    &-switcher {
      align-items: center;
      display: flex;
      justify-content: center;
      height: 16px;

      .ddo-btn-link {
        border-radius: 0;
        font-weight: 400;
        text-transform: uppercase;

        &:hover {
          color: $color-white;
          text-decoration: underline;
        }

        &:not(:last-child) {
          border-right: 1px solid $color-white;
        }

        &.active {
          cursor: default;
          font-weight: 600;
          text-decoration: none;
        }
      }
    }
  }

  &-digital-office {
    align-items: center;
    background-color: $color-blue-dark-1;
    border-radius: 22px;
    display: flex;
    justify-content: center;
    margin: 5px 10px;
    max-height: 48px;
    padding: 8px 12px 8px 20px;

    img {
      height: 28px;
      width: 28px;
      margin-right: 0.45rem;
    }

    a {
      color: $color-white;
      font-size: 600;
      line-height: 1.2;
      max-width: 50px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-user-panel {
    align-items: center;
    display: flex;
    height: inherit;
    margin-left: auto;
  }

  &-user-status {
    background-color: $color-blue-dark-1;
    color: $color-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: inherit;
    margin: 0 0 0 1rem;
    padding: 0 20px 0 10px;

    .btn-logout {
      margin: 0.5rem 0 0;
      text-decoration: underline;
    }
  }
}
