@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.scss';

.mobility-planner-page {
  font-family: $font-default;
  margin: 0 auto;
  max-width: $width-xxl;
  padding: 0 15px;
  width: 100%;

  .header-toolbar {
    align-items: center;
    display: flex;
    height: 58px;
    padding: 12px 24px;
    position: absolute;
    top: 0;

    &-actions {
      align-items: center;
      display: flex;
      gap: 10px;
      right: 0;

      .btn-export {
        @include apply-button-color($color-green-grass);
        padding: 5px 12px;
      }

      .filter-field {
        align-items: center;
        display: flex;
        height: 36px;
        position: relative;

        .btn-reset {
          align-items: center;
          background-color: $color-blue-dark-1;
          border-radius: $border-radius-small;
          cursor: pointer;
          display: flex;
          height: 36px;
          justify-content: center;
          padding: 8px 8px 8px 0;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;

          svg {
            fill: $color-white;
            height: 14px;
            width: 14px;
          }
        }

        .input-search {
          background-color: $color-white;
          border: none;
          border-radius: $border-radius-small;
          color: $color-grey-middle-1;
          font-size: 12px;
          min-width: 200px;
          outline: none;
          padding: 8px;
        }
      }
    }

    &-calendar {
      align-items: center;
      display: flex;
      left: 80px;

      .ant-picker {
        background: none;
        border: none;
        box-shadow: none;
        cursor: pointer;
        padding: 0;

        &-clear {
          display: none;
        }

        input {
          display: none;
        }
      }

      svg {
        fill: $color-white;
      }
    }

    &-resource-switcher {
      align-items: center;
      display: flex;
      left: 0;

      .resource-switcher {
        cursor: pointer;
      }
    }
  }

  .drive-form-popup {
    .modal-header {
      .btn-close {
        right: 18px;
      }

      .title {
        background-color: $color-grey-dark-3;
        font-size: 18px;
        padding: 14px 20px;
      }
    }
  }
}

body:has(.mobility-planner-page) {
  background-color: #333333;
  min-width: 900px;
}
