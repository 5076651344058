@import '@assets/styles/extends.scss';
@import '@assets/styles/mixins.scss';
@import '@assets/styles/variables.scss';

.driver-item {
  display: flex;
  margin-bottom: 10px;

  .driver-ctrl {
    align-items: center;
    display: flex;
    margin-left: 12px;
    width: 104px;

    .btn {
      font-weight: 600;
      height: 45px;
      line-height: 1.36;
      padding: 2px 12px;
      width: 100%;

      span {
        text-align: center;
        white-space: inherit;
      }

      &-available {
        @include apply-button-color($color-green-grass);
      }

      &-unavailable {
        @include apply-button-color($color-red-middle-1);
      }
    }
  }

  .driver-details {
    display: flex;
    flex-grow: 1;
    position: relative;
    padding: 10px;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      height: 68px;
      margin-top: -34px;
      border-right: 1px solid #ffffff60;
    }

    &-agency {
      @extend .flex-col-center;
      font-family: monospace;
      font-size: 18px;
      font-style: italic;
      padding: 0 1rem;
      width: 78px;
    }

    &-info {
      align-items: flex-start;
      display: flex;
      flex: 1 1;
      flex-direction: column;
      justify-content: center;
      line-height: 1.36;
      padding-left: 1rem;
      position: relative;

      &-title {
        align-items: center;
        display: flex;
        margin: 0 0 5px;
      }

      .blood {
        display: flex;
        position: absolute;
        bottom: 5px;
        right: 10px;

        svg {
          fill: $color-grey-light-3;
          height: 18px;
          margin-right: 7px;
          width: 13px;
        }
      }

      .contact {
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        height: 24px;
        min-height: auto;

        .icon {
          width: 12px;
          height: 12px;
        }
      }

      .name {
        @extend .text-ellipsis;
        font-size: 25px;
        max-width: 360px;
      }

      .shared {
        background-color: $color-blue-primary;
        color: $color-white;
        margin-left: 10px;
        padding: 0 10px;
        border-radius: $border-radius-md;
        display: inline-block;
        line-height: 20px;
      }
    }
  }

  .driver-info {
    background-color: $color-blue-dark-3;
    border-radius: 8px;
    color: $color-white;
    display: flex;
    max-width: 945px;
    min-height: 134px;
    width: 100%;
  }

  .driver-license {
    display: flex;
    flex-direction: column;
    padding: 14px 18px 14px 24px;
    width: 320px;

    &-header {
      align-items: center;
      color: $color-grey-light-3;
      display: flex;
      font-size: 14px;
      letter-spacing: 0.5px;
      line-height: 1.42;
      margin: 4px 0 15px;

      svg {
        fill: $color-white;
        height: 18px;
        margin-right: 10px;
        opacity: 0.7;
        width: 24px;
      }
    }

    &-data {
      display: flex;
      justify-content: space-between;
      letter-spacing: 0.45px;

      .label {
        color: $color-grey-light-3;
        font-size: 12px;
        line-height: 1.42;
      }

      .value {
        color: $color-white;
        font-size: 14px;
        line-height: 1.36;
      }
    }

    .btn-edit {
      align-self: baseline;
      margin-left: auto;
      min-height: 20px;
      opacity: 0.5;
      top: -5px;

      svg {
        fill: $color-white;
      }
    }
  }

  .driver-photo {
    border-radius: 8px 0 0 8px;
    display: flex;
    flex: 0 0 130px;
    flex-direction: column;
    min-height: 100%;
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .driver-status {
    background-color: $color-red-dark-1;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.2;
    padding: 4px 0;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}
