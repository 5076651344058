@import '@assets/styles/variables.scss';

.form-driver-edit {
  padding: 10px 15px 5px 10px;

  h2 {
    color: $color-grey-dark-2;
    font-size: 22px;
    font-weight: 500;
    margin: 0 0 1rem;
  }

  h3 {
    color: $color-black;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .row {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    margin-bottom: 14px;

    &-group-right {
      display: flex;
      gap: 15px;
      justify-content: space-between;
      margin-left: auto;
    }
  }

  .driver {
    justify-content: flex-start;

    &-photo {
      border-radius: $border-radius-small;
      height: 67px;
      position: relative;
      overflow: hidden;
      width: 65px;

      &-placeholder {
        display: flex;

        svg {
          height: 100%;
          width: 100%;
        }
      }

      img {
        height: 100%;
        width: 100%;
      }
    }

    &-info {
      margin: 0 12px;
      min-width: 200px;

      .name {
        color: $color-grey-dark-2;
        font-size: 18px;
        font-weight: bold;
      }

      .shared {
        color: $color-grey-middle-1;
        font-size: 12px;
        margin: 2px 0 0;

        &-label {
          background-color: $color-blue-secondary;
          border-radius: 30px;
          color: $color-white;
          font-size: 12px;
          margin-left: 5px;
          padding: 2px 10px;
        }
      }
    }

    &-license {
      justify-content: flex-start;
    }
  }

  .field {
    &-blood-group {
      margin-left: auto;
      width: 95px;
    }

    &-email {
      width: 240px;
    }

    &-expiration {
      min-width: 160px;
    }

    &-preferred {
      margin-left: auto;
      width: 220px;
    }

    &-shared {
      flex: 1;
      max-width: 850px;
    }
  }

  .footer {
    align-items: center;
    display: flex;
    padding: 1rem 0;

    .btn-submit {
      margin-left: auto;
      min-width: 140px;
    }

    .info {
      font-size: 14px;
    }
  }
}
