@import '@assets/styles/extends.scss';
@import '@assets/styles/mixins.scss';
@import '@assets/styles/variables.scss';

.booking-form-page {
  max-width: 768px !important;
  padding: 1em 0 !important;

  h1 {
    align-items: center;
    display: flex;
    gap: 15px;
    justify-content: center;

    svg {
      fill: $color-white;
    }
  }

  .section-title {
    align-items: center;
    color: $color-white;
    display: flex;
    font-size: 20px;
    font-weight: 500;
    position: relative;
    text-transform: capitalize;
    white-space: nowrap;
    width: 100%;

    &::after {
      background-color: $color-white;
      content: '';
      height: 1px;
      margin-left: 15px;
      overflow: hidden;
      width: -webkit-fill-available;
    }
  }

  .booking-form {
    display: flex;
    flex-direction: column;
    gap: 1em;

    > section {
      padding: 0 0 1em;

      h2 {
        @extend .section-title;
        margin-bottom: 1em;
      }

      section {
        padding: 0 0 2vh;
      }

      .multileg {
        &-ctrl {
          display: flex;
          justify-content: center;
        }

        &-btn-add {
          align-items: center;
          display: flex;
          justify-content: center;
          min-width: 180px;

          svg {
            margin-right: 10px;
          }
        }

        &-btn-remove {
          color: $color-red-middle-1;
          font-size: 24px;
          padding: 0 0.5rem;
        }
      }
    }

    fieldset {
      display: flex;
      flex-wrap: wrap;
      gap: 15px 30px;
      padding: 0 0 2vh;
      width: 100%;

      .line {
        border-top: 1px dashed $color-white;
        color: $color-white;
        display: flex;
        flex: 1 0 100%;
        font-size: 11px;
        justify-content: center;
        max-width: 100%;
        padding: 0.5em 0 0;
      }

      legend {
        @extend .section-title;
        margin-bottom: 1em;
      }

      section {
        display: flex;
        flex-wrap: wrap;
        gap: 15px 30px;
        padding: 0;
        width: 100%;

        h2 {
          @extend .section-title;
          margin-bottom: 0.25em;
        }
      }
    }

    .field {
      font-family: $font-default;
      flex: 50%;
      max-width: calc(50% - 15px);
      padding-bottom: 12px;

      &-error {
        color: #fa5252;
        font-family: $font-default;
        font-size: 0.75rem;
        line-height: 1.2;
        position: absolute;
        text-decoration: none;
        word-break: break-word;
      }

      &.booking-form-type {
        margin: 0 0 1.2rem;
      }

      &.wide {
        flex: 1 0 100%;
        max-width: 100%;
      }

      label,
      .input-radio-label,
      .time-picker-label {
        color: $color-white !important;
        font-size: 16px !important;
        font-weight: 500;
        margin: 0 0 5px;
      }

      .input-text-description {
        position: relative;
        top: -5px;
      }

      .input-text-wrapper,
      .select-wrapper {
        margin: 0 0 2px;
      }

      .input-text-wrapper {
        button {
          span {
            margin: 0;
          }
          svg {
            fill: $theme-dark-color-grey-6;
          }
        }
      }
    }

    .input-text-root:has([hidden]) {
      display: none;
    }

    .transfer-type {
      width: 100%;

      &-label {
        align-items: center;
        display: flex;
        gap: 10px;
        justify-content: center;
      }

      &:has([data-disabled]) {
        background: #82c91e;
      }

      svg {
        fill: $color-black;
      }

      [data-active],
      [data-disabled] {
        color: $color-white;

        svg {
          fill: $color-white;
        }
      }
    }

    .recurring {
      align-items: center;
      display: flex;
      flex-direction: column;

      h2 {
        margin: 0;
      }

      .btn-recurring {
        font-weight: 600;
        padding: 0 2rem;
      }

      &-info {
        border-bottom: 1px solid $color-white;
        color: $color-white;
        padding: 0 0 0.75rem;
        width: 100%;

        &-item {
          display: flex;
          gap: 8px;
          font-size: 0.9rem;
          padding: 5px 0;
        }

        h3 {
          font-size: 1rem;
          font-weight: 600;
        }
      }
    }

    .file-upload {
      &-list {
        margin: 0.5rem 0 0;

        &-item {
          &-name {
            overflow: hidden;
            position: relative;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 260px;
          }

          &-remove {
            svg {
              fill: $color-red-middle-1 !important;
            }
          }

          span {
            align-items: center;
            color: $color-white;
            display: flex;
          }

          svg {
            fill: $color-white;
          }
        }
      }
    }

    footer {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .btn {
        font-weight: bold;
        height: 38px;
        min-width: 160px;
      }

      .checkbox {
        &-label {
          color: $color-white;
          font-size: 12px !important;
        }
      }
    }
  }
}

.booking-success-section {
  color: $color-white;
  display: flex;
  flex-direction: column;
  margin: 5rem auto 1rem;
  max-width: 520px;

  &-status {
    background-color: $color-green-light-2;
    border-radius: $border-radius-small;
    margin: 0 0 2rem;
    padding: 1.3rem;

    h2 {
      font-size: 1.4rem;
      font-weight: 500;
      margin: 0 0 1rem;
    }

    p {
      font-size: 1.2rem;
    }
  }

  &-title {
    align-items: center;
    display: flex;
    font-size: 1.4rem;
    font-weight: 400;
    gap: 15px;
    justify-content: center;
    margin: 0 0 2rem;

    svg {
      fill: $color-white;
    }
  }

  footer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;

    .btn {
      &:not(:last-child) {
        flex: 50%;
        max-width: calc(50% - 10px);
      }

      &:last-child {
        flex: 100%;
      }

      &-clinic {
        @include apply-button-color($color-cyan);
      }

      &-guesthouse {
        @include apply-button-color($color-green-light-1);
      }

      &:hover {
        color: $color-white;
      }
    }

    h3 {
      display: flex;
      flex: 100%;
      font-size: 1.3rem;
      font-weight: 400;
      justify-content: center;
    }
  }
}

.booking-location-section {
  align-items: flex-end;
  display: flex;
  gap: 1rem;
  margin: 7rem auto 1rem;
  max-width: 778px;

  label {
    color: $color-white;
    font-size: 16px;
    margin: 0 0 5px;
  }
}
