@import '@assets/styles/variables.scss';

form {
  font-family: $font-default !important;

  &.theme-dark {
    .date-picker-label,
    .dropdown-label,
    .input-number-label,
    .input-text-label,
    .input-time-label,
    .multi-select-label,
    .textarea-label {
      color: $color-white;
      font-family: $font-default !important;
      font-size: 14px;
      line-height: 1.4;
      margin: 0 0 5px;
    }

    .date-picker-wrapper,
    .dropdown-input,
    .input-number,
    .input-text,
    .input-time {
      &[data-disabled] {
        color: $color-black;
      }
    }

    .dropdown {
      &-input {
        cursor: pointer;
      }

      &-wrapper {
        margin: 0 0 2px;
      }
    }

    .switch {
      &-bar {
        cursor: pointer;
      }

      &-label {
        color: $color-white;
        font-family: $font-default !important;
        font-size: 14px;
      }
    }

    [data-invalid] {
      border-color: $color-error;
    }
  }

  &.theme-light {
    .date-picker-label,
    .dropdown-label,
    .input-number-label,
    .input-text-label,
    .input-time-label {
      color: $color-grey-middle-1;
      font-family: $font-default !important;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.4;
      margin: 0 0 5px;
    }

    .date-picker-wrapper,
    .dropdown-input,
    .input-number,
    .input-text,
    .input-time {
      &[data-disabled] {
        color: inherit;
      }
    }

    .dropdown-input {
      cursor: pointer;
    }

    [data-invalid] {
      border-color: $color-error;
    }
  }

  .field {
    &-error {
      color: $color-error;
      font-family: $font-default;
      font-size: 0.75rem;
      line-height: 0.75;
      margin: 0;
      position: absolute;
      text-decoration: none;
      word-break: break-word;
    }

    &-info {
      color: $theme-dark-color-blue-light-1;
      font-family: $font-default;
      font-size: 0.75rem;
      line-height: 0.85;
      margin: 0;
      position: absolute;
      text-decoration: none;
      word-break: break-word;
    }
  }
}
