@import '@assets/styles/variables.scss';

.create-invoice-page {
  align-self: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: $font-default;
  max-width: $width-md;
  width: 100%;
  margin: 0 auto;

  > div.header {
    border-bottom: 1px solid $color-white;
    display: flex;
    flex-direction: row;
    padding: 15px 0;
    margin: 20px 0 25px;
    max-height: 60px;

    .title {
      color: $color-white;
      font-size: 24px;
      font-weight: normal;
      line-height: 1.37;
      margin: 0;
    }
  }

  > .row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 0 20px;

    .form-panel {
      margin: 0;
      width: 100%;

      h3 {
        span {
          font-size: 16px;
          font-weight: 400;
          margin-left: 24px;
        }
      }
    }

    .col-left {
      margin: 0;
      width: 50%;
    }

    .col-right {
      margin: 0 0 0 20px;
      width: 50%;
    }

    .comments {
      padding: 0 20px 15px 10px;
    }
  }

  .balance {
    display: flex;
    justify-content: flex-end;
    margin: 0 0 20px;
  }

  .btn {
    &-confirm {
      margin-left: auto;
    }
  }
}

.invoice-created-modal {
  text-align: center;

  svg {
    width: 40px;
    height: 40px;
    margin-bottom: 15px;
  }

  .greeting {
    margin: 0 10px 25px;
  }

  .title {
    font-weight: 900;
    margin: 0 0 10px;
  }

  .sub-text {
    font-size: 16px;
    font-style: italic;
    margin: 10px 0 10px;
  }

  .loader {
    min-height: 162px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin: 30px 0 0;
  }

  .button {
    margin: 20px auto 0;
  }

  .icon {
    margin-top: 20px;
  }
}
