@import '@assets/styles/mixins.scss';
@import '@assets/styles/variables.scss';

.booking-details-title {
  margin: 40px auto 0 !important;
  width: 1060px;
}

.booking-details-container {
  background-color: $theme-dark-bg-accent;
  border-radius: $border-radius-small;
  color: $color-white;
  margin: 0 auto 40px !important;
  min-height: 400px;
  padding: 20px 40px;
  width: 1060px !important;

  hr {
    background-color: $theme-dark-color-grey-3;
  }

  .booking-label {
    align-items: center;
    border: 1px solid transparent;
    border-radius: 4px;
    color: $color-white;
    cursor: default;
    display: flex;
    font-size: 14px;
    height: 30px;
    min-width: 140px;
    padding: 6px 30px;
  }

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .btn {
    min-width: 140px;
    height: 30px;
    padding: 6px 30px;
    color: $color-white;
    font-size: 14px;
  }

  .asset-details {
    grid-template-columns: repeat(11, 1fr);
    grid-template-rows: repeat(4, auto);
    gap: 20px;

    > div {
      background-color: $theme-dark-color-grey-9;
    }

    .row-1-col-1 {
      grid-area: 1 / 1 / 1 / 3;
    }

    .row-1-col-2 {
      grid-area: 1 / 3 / 1 / 5;
    }

    .row-1-col-3 {
      grid-area: 1 / 5 / 1 / 8;
    }

    .row-1-col-4 {
      grid-area: 1 / 8/ 1 / 12;
    }

    .row-2-col-1 {
      grid-area: 2 / 1 / 2 / 2;
    }

    .row-2-col-2 {
      grid-area: 2 / 2 / 2 / 4;
    }

    .row-2-col-3 {
      grid-area: 2 / 4 / 2 / 7;
    }

    .row-2-col-4 {
      grid-area: 2 / 7 / 2 / 12;
    }

    .row-3-col-1 {
      grid-area: 3 / 1 / 3 / 4;
    }

    .row-3-col-2 {
      grid-area: 3 / 4 / 3 / 6;
    }

    .row-3-col-3 {
      grid-area: 3 / 6 / 3 / 9;
    }

    .row-3-col-4 {
      grid-area: 3 / 9 / 3 / 12;
    }

    .row-4-col-1 {
      grid-area: 4 / 1 / 4 / 3;
    }

    .row-4-col-2 {
      grid-area: 4 / 3 / 4 / 6;
    }

    .row-4-col-3 {
      grid-area: 4 / 6 / 4 / 9;
    }

    .row-5-col-1 {
      grid-area: 5 / 1 / 5 / 12;
    }

    .line-break {
      .hbh-details-item-value {
        overflow: inherit;
        text-overflow: inherit;
        white-space: inherit;
      }
    }
  }

  .booking-route {
    .link-google-map {
      display: inline-block;
    }

    .prefix {
      color: $color-yellow-middle-1;
      font-size: 1rem;
    }

    .route-details {
      grid-template-columns: repeat(11, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 20px;

      > div {
        background-color: $theme-dark-color-grey-9;
      }

      &.route-canceled {
        opacity: 0.3;
      }

      .row-1-col-1 {
        grid-area: 1 / 1 / 1 / 4;
      }

      .row-1-col-2 {
        grid-area: 1 / 4 / 1 / 6;
      }

      .row-1-col-3 {
        grid-area: 1 / 6 / 1 / 9;
      }

      .row-1-col-4 {
        grid-area: 1 / 9 / 1 / 11;
      }

      .row-2-col-1 {
        grid-area: 2 / 1 / 2 / 4;
      }

      .row-2-col-2 {
        grid-area: 2 / 4 / 2 / 11;
      }

      .row-3-col-1 {
        grid-area: 3 / 1 / 3 / 4;
      }

      .row-3-col-2 {
        grid-area: 3 / 4 / 3 / 11;
      }

      .row-4-col-1 {
        grid-area: 4 / 1 / 4 / 6;
      }

      .row-4-col-2 {
        grid-area: 4 / 6 / 4 / 11;
      }
    }
  }

  .recurring-drive {
    grid-template-columns: 1fr 2fr repeat(2, 1fr);

    > div {
      background-color: $theme-dark-color-grey-9;
    }
  }

  .booking-requestor-details,
  .booking-passengers-details {
    display: flex;
    flex-direction: column;
    margin: 30px 0;

    .asset-requestor,
    .asset-passenger {
      grid-template-columns: repeat(11, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 20px;

      > div {
        background-color: $theme-dark-color-grey-9;
      }

      .col-1 {
        grid-area: 1 / 1 / 1 / 3;
      }

      .col-2 {
        grid-area: 1 / 3 / 1 / 6;
      }

      .col-3 {
        grid-area: 1 / 6 / 1 / 9;
      }

      .col-4 {
        grid-area: 1 / 9/ 1 / 12;
      }
    }

    .details-panel {
      border-top: 1px solid $theme-dark-color-grey-3;
      border-bottom: 1px solid $theme-dark-color-grey-3;
      color: $color-grey-light-2;
      display: flex;
      font-size: 16px;
      justify-content: space-between;
      padding: 14px 0;

      .btn-requestor {
        cursor: pointer;
        transition: transform 0.5s;

        &.open {
          transform: rotate(180deg);
        }

        svg {
          fill: $color-grey-light-2;
        }
      }
    }

    .panel-content {
      max-height: 0;
      overflow: hidden;
      padding: 20px 0 0;
      transition: all 0.3s ease-out;

      &.open {
        max-height: 170px;
        transition: all 0.3s ease-out;
      }
    }
  }

  .booking-passengers-details {
    margin-top: 0;

    .asset-passenger {
      grid-template-rows: 1fr;
    }

    .panel-content {
      transition-duration: 0.5s;

      &.open {
        transition-duration: 0.5s;
        max-height: fit-content;
      }
    }
  }

  .attachments {
    .hbh-details-item-label {
      margin: 0;
    }

    .hbh-details-item-value {
      height: 100%;
    }

    .attachment {
      border: 1px solid $color-grey-dark-4;
      border-radius: 4px;
      background-color: #1e2021;
      display: flex;
      align-items: center;
      padding: 5px 10px;
      cursor: pointer;
      transition: 0.2s;
      color: inherit;
      height: 100%;

      svg {
        width: 30px;
        height: 30px;
        fill: $color-grey-light-1;
        margin-right: 20px;
      }

      h5 {
        font-size: 18px;
        color: $color-white;
      }

      &:hover {
        border-color: darken($color-blue-primary, 10%);
        background-color: lighten(#1e2021, 10%);
      }
    }
  }

  .footer {
    align-items: center;
    background-color: $theme-dark-color-grey-85;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 12px 18px;

    .buttons {
      display: flex;
      gap: 12px;

      .btn {
        min-width: 100px;
      }

      .btn-cancel {
        @include apply-button-color($color-red-light-1);
      }

      .btn-edit {
        @include apply-button-color($color-yellow-middle-1);
      }
    }
  }
}
