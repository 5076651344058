@import '@assets/styles/variables.scss';

.vehicle-list {
  max-width: $width-md !important;

  &-footer {
    display: flex;
    margin: 20px 116px 0 0;

    .last-update {
      color: $color-grey-light-1;
      font-size: 13px;
    }

    .pagination {
      margin-left: auto;
    }
  }

  &-tabs {
    .tab {
      &.active {
        border-bottom: 2px solid $color-green-light-2;

        .badge {
          background-color: $color-green-light-2;
        }
      }
    }
  }

  &-title {
    margin: 10px 0 5px;
  }
}
