@import '@assets/styles/variables.scss';

.switch {
  align-items: center;
  display: flex;

  &-track {
    cursor: pointer;
  }

  button:not(.ant-switch-checked) {
    background-color: $color-grey-middle-1;
  }

  .label {
    color: $color-grey-middle-1;
    display: inline-block;
    font-size: 12px;
    line-height: 1.4;
    margin-left: 15px;
  }
}
