@import '@assets/styles/variables.scss';

.dialog-dark.dialog {
  background: $theme-dark-color-grey-9;
  border: 1px solid $theme-dark-color-grey-7;
  border-radius: $border-radius-md;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.16);
  color: $color-white;
  min-width: 14rem;
  width: auto;

  &.loading {
    opacity: 0.5 !important;
    pointer-events: none;
  }
}
