@import './mixins.scss';

$media-title-font-size: 16px;

.ddo-header {
  @media screen and (max-width: 620px) {
    .ddo-header-logo {
      margin-left: 1rem;
      > svg {
        width: 95px;
      }
    }

    &-user-panel {
      svg {
        display: none;
      }
    }
  }
}

main.ddo-main-layout {
  .ddo-page-wrapper {
    @media screen and (max-width: 1060px) {
      .booking-details-title {
        max-width: 800px;
        width: 100%;
      }

      .booking-details-container {
        max-width: 800px;
        width: 100%;
      }
    }

    @media screen and (max-width: 900px) {
      .hbh-container {
        padding: 10px;
      }
    }

    @media screen and (max-width: 768px) {
      .booking-details-title {
        margin: 25px 15px 0 !important;
        max-width: inherit;
        width: 100%;
      }

      .booking-details-container {
        max-width: inherit;
        width: 100% !important;

        .panel-content.open {
          max-height: inherit;
        }
      }

      .hbh-details-container {
        display: flex;
        flex-direction: column;
      }
    }

    @media screen and (max-width: 620px) {
      .hbh-main-menu-container {
        .hbh-menu-panel {
          margin: 0;
          max-width: 360px;
          min-width: auto;

          .hbh-menu-panel-header {
            .logo {
              display: none;
            }

            .title {
              font-size: $media-title-font-size;
              margin: 0;
              text-align: center;
            }
          }

          .hbh-menu-panel-content {
            align-items: center;
            flex-direction: column;

            .hbh-menu-button {
              margin-right: 0;
              min-width: 300px;
            }

            .hbh-submenu-container {
              align-items: center;
              flex-direction: column;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1060px) {
  .invoice-list-page {
    .container {
      .form {
        .field-search {
          width: 40%;

          .btn-reset {
            min-width: 30px;
          }
        }

        .filters {
          width: 50%;
        }
      }
    }

    .hbh-table {
      overflow-x: scroll;

      > div {
        min-width: 1200px;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .booking-list-page .table,
  .invoice-history-container .hbh-table,
  .summary-list-table {
    overflow-x: scroll;

    > div {
      min-width: 1000px;
    }
  }

  body:has(.vehicles-checklist, .dhx_cal_navline) {
    background-color: #333333;
    min-width: 900px;
  }
}

@media screen and (max-width: 620px) {
  .tabs {
    .hbh-tab-select {
      font-size: 12px;
      min-width: auto;
    }
  }

  .cost-recovery-report-page {
    .summary {
      .export-fields {
        width: 35%;

        .field {
          width: 100%;
        }
      }

      .filters {
        width: 55%;
      }
    }
  }

  .create-invoice-page {
    padding: 0 10px;

    .header {
      .title {
        font-size: 16px;
      }
    }

    ul.passenger-details,
    ul.details {
      gap: 10px;
      padding: 0 !important;

      li {
        flex: 48% !important;
        margin-bottom: 12px;

        &:last-child {
          flex: 48%;
        }
      }
    }

    .charges-container {
      .options {
        .dropdown {
          min-width: 120px;
        }
      }
    }

    > .row.row-2-columns {
      flex-direction: column;

      .form-panel {
        margin: 0 0 15px;
        padding: 0;
        width: 100%;
      }
    }
  }

  .driver-entry-page {
    .driver-entry-header {
      .field-agency {
        flex-direction: column;
        z-index: 99;

        span {
          margin: 0 0 12px;
        }
      }
    }
  }

  .driver-list {
    .modal-box-light {
      min-width: 360px;

      .form-driver-edit {
        padding: 0;

        h2 {
          font-size: 16px;
          margin: 0 0 8px;
        }

        > .row {
          gap: 4px;
          flex-direction: column;
          margin-bottom: 10px;

          .field {
            max-width: inherit;
            width: 100%;

            label {
              margin-bottom: 2px;
            }
          }
        }

        .driver-license,
        .driver-location {
          width: 100%;

          > .row {
            gap: 4px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .invoice-history-container {
    .filters-container {
      .form {
        .field-search {
          width: 45%;

          .btn-reset {
            min-width: 30px;
          }
        }

        .filters {
          width: 50%;
        }
      }
    }
  }

  .invoice-list-page {
    padding: 0 10px;

    .header {
      .title {
        font-size: 16px;
      }
    }

    .container {
      .form {
        flex-direction: column;

        .field-search {
          margin-bottom: 15px;
          width: 100%;

          .btn-reset {
            min-width: 110px;
          }
        }

        .filters {
          width: 100%;
        }
      }
    }
  }
}
