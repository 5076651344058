@import '@assets/styles/variables.scss';

.details-block {
  background: $color-grey-dark-4;
  @extend .details-block-border;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  h4 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    color: #b8bfc4;
  }
  p {
    font-size: 16px;
    color: #e0e8ed;
  }
}

.details-block-expanded {
  @extend .details-block-border;
  background: $color-grey-dark-4;
  padding-bottom: 8px;
  display: block;
  overflow: auto;
  width: 714px;
  padding-top: 8px;
}

.details-block-border {
  border: 1px solid $color-grey-dark-5;
  border-radius: 4px;
}

.details-block-tag {
  @extend .details-block;
  justify-content: space-between;
  width: 330px;
  margin-right: 0;
}

.details-block-titled {
  @extend .details-block;
  flex-direction: column;
  margin-right: 0;
  padding: 16px;
  align-items: flex-start;
  h4 {
    font-weight: 600;
    font-size: 14px !important;
    text-transform: uppercase;
    color: #b8bfc4;
  }
}

.details-block-location {
  @extend .details-block;
  flex-direction: row;
  margin-right: 0;
  width: 684px;
  padding: 16px;
  flex: 1;
  align-items: flex-start;
  h4 {
    margin-bottom: 13px;
  }
  p {
    width: 260px;
    margin-left: 12px;
  }
}

.details-block-stars {
  display: flex;
  flex: 1;
  margin-left: 16px;
  margin-top: -8px;
}

.details-block-star-container {
  margin-right: 9px;
  margin-top: 4px;
}

.details-block-status {
  border-radius: 4px;
  padding: 8px 12px;
  height: 100%;
  display: flex;
  align-items: center;
  p {
    color: #e0e8ed;
    font-size: 12px !important;
  }
}

.details-block-status-active {
  @extend .details-block-status;
  background: #009e10;
  border: 1px solid #009e10;
}

.details-block-status-inactive {
  @extend .details-block-status;
  background: #37393a;
  border: 1px solid $color-grey-dark-5;
}

.details-block-signature {
  background: #3c8545;
  height: 18px;
  width: 18px;
  border-radius: 100px;
  margin: 20px 8px 16px 11px;
}

.details-block-signature-text {
  font-weight: 400;
  font-size: 14px !important;
  color: #b8bfc3;
}

.details-block-signature-status-text {
  font-weight: 600;
  font-size: 12px !important;
  text-transform: uppercase;
}

.details-block-info-tag {
  background: $color-blue-dark-bg;
  border-radius: 4px;
  border: 1px solid #095d93;
  height: 41px;
  padding: 8px;
  align-items: center;
  display: flex;
  p {
    color: #e0e8ed;
    font-weight: normal;
    font-size: 18px;
    text-transform: none;
  }
}

.details-block-distance-tag {
  flex-direction: column;
  display: flex;
  background: $color-blue-dark-bg;
  border-radius: 4px;
  border: 1px solid #095d93;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  p {
    color: #e0e8ed;
    font-weight: normal;
    font-size: 18px;
    text-transform: none;
  }
}

.details-block-distance-tag-inactive {
  background: #37393a !important;
  border: 1px solid $color-grey-dark-5 !important;
  @extend .details-block-distance-tag;
  min-width: 64px;
  p {
    color: #677782 !important;
  }
}

.details-block-location-pick-up {
  margin-right: 115px;
}

.details-block-status-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.details-block-status-comment {
  color: #e0e8ed;
  font-weight: 400;
  font-size: 16px;
  font-style: italic;
  padding-left: 8px;
  padding-right: 8px;
  width: 651px;
}

.details-block-chevron {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 16px;
  margin-right: 8px;
}
