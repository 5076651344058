@import '@assets/styles/variables.scss';

.input-radio {
  &-label {
    color: $color-grey-middle-1;
    cursor: pointer;
    font-size: 14px;
  }

  &-horizontal {
    align-items: center;
    display: flex;
    gap: 25px;

    div[role='radiogroup'] {
      align-items: center;
      display: flex;
      gap: 20px;
    }
  }

  &-vertical {
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;

    label {
      margin-bottom: 10px;
    }
  }

  &-error {
    color: $color-error;
    font-size: 9px;
    margin: 0;
    position: absolute;
  }

  input:disabled,
  label[data-disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.theme-dark {
  .input-radio {
    &-label {
      color: $color-white;
    }
  }
}
