@import '@assets/styles/mixins.scss';
@import '@assets/styles/variables.scss';

.btn-country-reporting {
  @include apply-button-color(#5c1f2e);
}

.btn-cost-recovery-summary {
  @include apply-button-color(#206f78);
}

.btn-dashboard {
  @include apply-button-color(#a58b06);
  flex-basis: 100% !important;
}
