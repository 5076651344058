@import "@assets/styles/variables.scss";

@mixin value {
  font-size: 30px;
  font-weight: bold;
  line-height: 1;
  color: $color-white;
  text-align: right;
  padding: 0 10px;
  width: 170px;
}

.invoice-total {
  align-items: center;
  background-color: $color-blue-primary;
  border-radius: 3px;
  color: $color-white;
  cursor: default;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  margin-left: 25px;
  transition: 0.2s background-color linear;

  &.wide {
    .value {
      min-width: 130px;
    }
  }

  .currency {
    font-size: 30px;
    font-weight: 400;
    padding-right: 10px;
  }

  .label {
    font-size: 12px;
    line-height: 1.4;
    padding-right: 20px;
  }

  .value {
    color: $color-white;
    font-size: 30px;
    font-weight: bold;
    line-height: 1;
    padding: 0 10px;
    text-align: right;
  }
}
