@import '@assets/styles/variables.scss';
@import '@assets/styles/extends.scss';

.form-vehicle-edit {
  padding: 10px 15px 5px 10px;

  h2 {
    color: $color-grey-dark-2;
    font-size: 22px;
    font-weight: 500;
    margin: 0 0 1rem;
  }

  h3 {
    color: $color-black;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .row {
    gap: 15px;
    justify-content: space-between;
    margin-bottom: 14px;
  }

  .vehicle {
    &-photo {
      background-color: $color-blue-dark-3;
      border-radius: $border-radius-small;
      height: 80px;
      position: relative;
      overflow: hidden;
      width: 160px;

      &-placeholder {
        @extend .flex-col-center;
        height: 100%;
        width: 100%;

        svg {
          fill: $color-grey-light-2;
          height: 40px;
          width: 65px;
        }
      }

      img {
        height: 100%;
        width: 100%;
      }
    }

    &-info {
      flex: 1;
      margin: 0 24px;
      min-width: 200px;

      .name {
        @extend .text-ellipsis;
        color: $color-grey-dark-2;
        font-size: 18px;
        font-weight: bold;
        max-width: 280px;
      }

      .gps-provider {
        .gps-error {
          color: $color-red-light-1;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }

  .field {
    &-error {
      color: $color-error;
      font-size: 9px;
      margin: 0;
      position: absolute;
    }

    &-preferred {
      width: 200px;
    }

    &-shared {
      flex: 1;
      max-width: 850px;
    }

    &-vehicle-model {
      width: 240px;
    }

    &-pax {
      width: 110px;
    }

    &-owner {
      width: 170px;
    }

    &-protection {
      width: 135px;
    }
  }

  .footer {
    align-items: center;
    display: flex;
    padding: 1rem 0;

    .btn-submit {
      margin-left: auto;
      min-width: 140px;
    }

    .info {
      font-size: 14px;
    }
  }
}
