@import '@assets/styles/mixins.scss';
@import '@assets/styles/variables.scss';

.dialog-scheduled-drive {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-width: 420px;
  padding: 0;

  header,
  section,
  footer {
    padding: 0.5rem 1rem;
    width: 100%;
  }

  header {
    border-bottom: 1px solid #e7e7e7;

    h2 {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  section {
    margin: 0.75rem 0 0;
    min-height: 5rem;

    .choice {
      margin: 0 -12px 4px;

      &-item {
        align-items: center;
        border: 1px solid transparent;
        display: flex;

        &-label {
          cursor: pointer;
          flex: 1;
          padding: 0.75rem 0;

          label {
            cursor: pointer;
          }
        }

        > div:first-child {
          display: none;
        }

        dl,
        dd {
          margin: 0;
        }
      }

      &[data-checked] {
        .choice-item {
          background: #eef8fe;
          border: 1px solid $theme-dark-color-blue-light-2;
          border-radius: $border-radius-small;
        }
      }
    }

    .scheduled-drive {
      &-item {
        align-items: center;
        display: flex;
        gap: 1rem;
        margin: 0 0 0.5rem;

        dd {
          margin: 0;
        }

        p {
          color: $theme-dark-color-grey-9;
          font-size: 0.85rem;
          font-weight: 600;

          &.sub {
            color: $theme-dark-color-grey-7;
            font-size: 0.75rem;
            font-weight: 400;
          }
        }

        span {
          color: $theme-dark-color-grey-6;
          font-size: 0.75rem;
        }

        svg {
          fill: $theme-dark-color-grey-6;
          height: 1rem;
          width: 1rem;
        }
      }
    }
  }

  footer {
    border-top: 1px solid #e7e7e7;
    display: flex;
    gap: 3rem;
    justify-content: flex-end;

    .btn {
      &-confirm {
        @include apply-button-color($theme-dark-color-green-dark-1);
      }
    }
  }
}
