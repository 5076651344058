@import '@assets/styles/variables.scss';

.page-login {
  align-items: center;
  background: url('../../assets/img/default/background.jpg') no-repeat;
  background-size: cover;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  position: relative;

  &:after {
    content: '';
    background-color: $color-black;
    opacity: 0.3;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }

  .login {
    align-items: center;
    background: url('../../assets/img/default/login_mask.png') no-repeat left top;
    background-color: $color-grey-middle-3;
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: column;
    margin-top: -160px;
    min-height: 240px;
    overflow: hidden;
    padding: 38px 90px 32px;
    position: relative;
    width: 500px;
    z-index: 1;

    &-greeting {
      color: $color-white;
      font-family: inherit;
      font-size: 26px;
      font-weight: 300;
      line-height: 1.37;
      letter-spacing: normal;
      display: flex;
      flex-direction: column;
      text-align: center;

      strong {
        font-weight: 700;
      }
    }

    &-form {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .login-field {
        align-self: stretch;

        &:first-child {
          margin-bottom: 15px;
        }

        input {
          padding: 6px 8px;
          font-size: 14px;
          border-radius: 3px;
          background-color: #55575d;
          color: $color-grey-light-2;
          height: initial;

          &::placeholder {
            color: $color-grey-light-5;
          }
        }

        small[role='alert'] {
          display: block;
          width: max-content;
          margin: 5px auto 0;
          font-size: 12px;
        }
      }
    }

    &-link {
      color: $color-blue-primary;
      font-size: 20px;
      margin-top: 20px;
    }
  }
}
