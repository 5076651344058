@import '@assets/styles/variables.scss';
@import '@assets/styles/extends.scss';
@import '@assets/styles/mixins.scss';

.invoice-list-page {
  align-self: center;
  display: flex;
  flex-direction: column;
  font-family: $font-default;
  max-width: $width-xxl;
  width: 100%;
  margin: 0 auto;

  > div.header {
    border-bottom: 1px solid $color-white;
    display: flex;
    flex-direction: row;
    padding: 15px 0;
    margin: 20px 0 25px;
    max-height: 60px;

    .title {
      color: $color-white;
      font-size: 20px;
      font-weight: normal;
      line-height: 1.37;
      margin: 0;
    }
  }

  .container {
    background-color: $color-white;
    border-radius: $border-radius-small;
    display: flex;
    flex-direction: column;
    padding: 15px 0;

    .row {
      @extend .flex;
      align-items: flex-start;
      margin-bottom: 20px;
    }

    .form {
      align-items: flex-end;
      display: flex;
      justify-content: space-between;
      padding: 0 25px 10px;
      width: 100%;

      .field-search {
        align-items: center;
        display: flex;
        width: 50%;

        .search-input {
          width: 100%;

          .input-text {
            border-radius: $border-radius-small 0 0 $border-radius-small;
          }
        }

        .btn-search {
          border-radius: 0 $border-radius-small $border-radius-small 0;
          width: 45px;
          height: 36px;
        }

        .btn-reset {
          color: $color-blue-primary;
          font-size: 12px;
          min-width: 95px;
        }
      }

      .filters {
        display: flex;
        justify-content: space-between;
        width: 40%;

        .agency {
          width: 35%;
        }

        .month {
          width: 35%;
        }

        .year {
          width: 25%;
        }
      }
    }

    table {
      thead {
        tr {
          th {
            &:first-child {
              width: 65px;
            }

            &:nth-child(2) {
              width: 100px;
            }

            &:nth-child(3) {
              width: 85px;
            }

            &:nth-child(4) {
              width: 70px;
            }

            &:nth-child(5) {
              width: 35px;
            }

            &:nth-child(6) {
              width: 60px;
            }

            &:nth-child(7) {
              width: 110px;
            }

            &:nth-child(8) {
              width: 95px;
            }

            &:nth-child(9) {
              width: 65px;
            }

            &:nth-child(10) {
              width: 45px;
            }

            &:nth-child(11) {
              width: 50px;
            }

            &:nth-child(12) {
              width: 55px;
            }

            &:nth-child(13) {
              width: 50px;
            }

            &:last-child {
              width: 100px;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            overflow: hidden;
            text-overflow: ellipsis;

            &:nth-child(3) {
              width: 90px;
            }

            &:nth-child(6) {
              text-transform: capitalize;
            }

            &:last-child {
              .btn-create,
              .btn-download {
                height: 28px;
                margin-right: 15px;
                min-height: auto;
                min-width: 116px;
              }

              .btn-download {
                background-color: $color-green-middle-1;
                border-color: $color-green-middle-1;
                font-size: 11px;

                &:hover {
                  background-color: lighten($color-green-middle-1, 10%);
                  border-color: lighten($color-green-middle-1, 10%);
                }
              }
            }
          }
        }
      }
    }

    .footer {
      padding: 10px 25px;
    }
  }
}

.text {
  width: 120px;
  display: block;
  @include text-cropping;
}
