@import '@assets/styles/mixins.scss';
@import '@assets/styles/variables.scss';

@mixin dark-theme-table {
  .ant-table {
    background: transparent;

    .ant-table-content {
      .ant-table-thead {
        th {
          background: transparent;
          padding: 16px 8px;

          &:first-child {
            padding-left: 1rem;
          }

          &:last-child {
            padding-right: 1rem;
          }
        }
      }

      .ant-table-tbody {
        tr {
          &:nth-child(even) {
            background-color: $theme-dark-color-grey-9;
          }

          &:nth-child(odd) {
            background-color: $theme-dark-color-grey-85;
          }

          td {
            background: none;
            border: none;
            padding: 8px;

            &:first-child {
              padding: 0px;
              width: 30px;
            }

            &:nth-child(7),
            &:nth-child(8) {
              white-space: normal;
            }

            &:last-child {
              padding-right: 1rem;
            }

            .sub {
              color: $theme-dark-color-grey-3;
              cursor: pointer;
              font-size: 0.85rem;
              font-style: normal;
              font-weight: 400;
              line-height: 1.25;
            }
          }
        }
      }
    }
  }
}

.booking-list {
  align-items: flex-start;
  background-color: $theme-dark-bg-accent;
  border-radius: $border-radius-small;
  display: flex;
  gap: 1.5rem;
  margin: 5rem auto;
  padding: 1.5rem 0 0;

  header {
    display: flex;
    gap: 2.4375rem;
    justify-content: space-between;
    padding: 0 1.5rem;
    width: 100%;

    h1 {
      color: $color-white;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 2.125rem;
    }

    .search {
      align-items: center;
      border: 1px solid $theme-dark-color-grey-1;
      border-radius: $border-radius-small;
      display: flex;
      gap: 0.75rem;
      max-height: 34px;
      padding: 0.1rem 0.5rem;
      position: relative;

      &-icon {
        color: $theme-dark-color-grey-1;
        height: 1.25rem;
        width: 1.25rem;
      }

      &-input {
        background: transparent;
        border: none;
        box-shadow: none;
        color: $theme-dark-color-grey-1;
        font-size: 1rem;
        font-weight: 400;
        height: 1.8rem;
        line-height: 1.8rem;
        min-width: 13rem;
        outline: none;

        &::placeholder {
          color: $theme-dark-color-grey-1;
        }
      }
    }
  }

  &-tabs {
    padding: 0 1.5rem;
    width: 100%;

    .tabs-wrap {
      margin: 0;
    }

    .tabs {
      .tab {
        font-size: 1rem;
        line-height: 1.5rem;
        padding: 4px 8px 10px !important;
        text-transform: none;

        &.active {
          &:first-child {
            background: rgba($theme-dark-color-red-alert, 0.2);
            border-bottom-color: $theme-dark-color-red-alert;
          }

          &:last-child {
            background: rgba($theme-dark-color-green-success, 0.2);
            border-bottom-color: $theme-dark-color-green-success;
          }
        }
      }
    }
  }

  &-panel {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    padding: 0 1.5rem;
    width: 100%;
  }

  &-export {
    display: flex;
    gap: 1rem;

    .btn {
      font-size: 0.85rem;
      @include apply-button-color($theme-dark-color-green-dark-1);

      svg {
        height: 0.85rem;
        width: 0.85rem;
      }
    }
  }

  .table {
    width: 100%;

    th {
      vertical-align: top;

      > div {
        flex-direction: row-reverse;
        gap: 0.25rem;

        .ant-table-column-sorter {
          margin: 0;
        }
      }
    }

    .ant-table-cell {
      &.ant-table-row-expand-icon-cell {
        vertical-align: middle;

        .icon-expand,
        .icon-expanded {
          cursor: pointer;
          margin: 0 auto;
          transform: scale(0.7);
          transition: transform 0.5s;

          svg {
            fill: $color-white;
          }
        }

        .icon-expanded {
          transform: rotate(180deg);
        }
      }
    }

    &.booking-list-not-processed {
      .hbh-table-dark {
        tr {
          th {
            &:nth-child(2) {
              width: 36px;
            }

            &:nth-child(3) {
              width: 110px;
            }

            &:nth-child(5) {
              width: 85px;
            }

            &:nth-child(6) {
              width: 100px;
            }

            &:nth-child(7),
            &:nth-child(8) {
              width: 210px;
            }

            &:nth-child(9) {
              width: 70px;
            }

            &:nth-child(10) {
              width: 30px;
            }

            &:last-child {
              width: 110px;
            }
          }
        }
      }
    }

    &.booking-list-processed {
      .hbh-table-dark {
        tr {
          th {
            &:nth-child(2) {
              padding: 0;
              width: 0;
            }

            &:nth-child(3) {
              width: 110px;
            }

            &:nth-child(5) {
              width: 85px;
            }

            &:nth-child(6) {
              width: 100px;
            }

            &:nth-child(7),
            &:nth-child(8) {
              width: 210px;
            }

            &:nth-child(9) {
              width: 70px;
            }

            &:nth-child(10) {
              width: 0px;
            }

            &:last-child {
              width: 150px;
            }
          }
        }
      }
    }

    .hbh-table-dark {
      @include dark-theme-table;

      tr {
        &.ant-table-expanded-row {
          > td {
            border-bottom: 1rem solid $color-grey-dark-6 !important;
            padding: 0 !important;

            .table-routes {
              border: 1px solid $color-blue-light;

              th {
                background-color: $color-grey-dark-6 !important;
                width: auto !important;

                &:first-child {
                  width: 50px !important;
                }

                &:nth-child(2),
                &:nth-child(4) {
                  width: 95px !important;
                }

                &:nth-child(3),
                &:nth-child(5) {
                  width: 280px !important;
                }

                &:nth-child(6) {
                  width: 140px !important;
                }
              }

              td {
                &:first-child {
                  text-align: center;
                }

                &:nth-child(3),
                &:nth-child(5) {
                  white-space: normal;
                }

                &:nth-child(8) {
                  width: 150px !important;
                }

                .btn-reject {
                  @include apply-button-color($theme-dark-color-red-alert);
                  font-size: 0.875rem;
                  font-weight: 600;
                  height: 30px;
                  margin-left: auto;
                }

                .route-state {
                  text-transform: capitalize;
                }
              }
            }
          }
        }

        th {
          &:first-child {
            width: 40px;
          }

          &:nth-child(11) {
            width: 115px;
          }
        }

        td {
          &:nth-child(11) {
            white-space: inherit;
          }
        }
      }

      .attachments {
        svg {
          fill: $color-white;
          width: 20px;
        }
      }

      .checkbox-outline {
        input[type='checkbox'] {
          background: none;
        }
      }

      .pax {
        align-items: center;
        display: flex;

        .icon-driver {
          margin-right: 1rem;
          width: 18px;
        }
      }

      .row-disabled {
        td {
          background: lighten($color-grey-dark-4, 10%) !important;
          pointer-events: none;
        }
      }

      .type-of-trip {
        align-items: center;
        display: flex;

        svg {
          fill: $color-white;
          margin-top: 3px;
          width: 22px;
        }
      }
    }

    .btn-forward,
    .btn-process {
      font-size: 0.875rem;
      font-weight: 600;
      height: 30px;
      position: relative;
      width: 100%;

      &:hover {
        color: $color-white;
      }

      > div {
        gap: 0.2rem;
      }

      span {
        margin: 0;
      }
    }

    .btn-forward {
      min-height: auto;
      padding: 4px 8px;

      svg {
        fill: $color-white;
        height: 14px;
        width: 15px;
      }
    }

    .btn-forwarded {
      svg {
        fill: $theme-dark-color-grey-5;
      }
    }

    .btn-process {
      min-height: auto;
      padding: 4px 4px 4px 8px;

      &-accepted {
        @include apply-button-color($theme-dark-color-blue-light-1);
      }

      &-cancelled,
      &-cancelled-fp {
        @include apply-button-color($theme-dark-color-yellow-1);
      }

      &-disabled {
        pointer-events: none;
      }

      &-finished {
        @include apply-button-color($theme-dark-color-grey-5);
      }

      &-not-processed {
        @include apply-button-color(darken($theme-dark-color-yellow-1, 10%));
      }

      &-ongoing {
        @include apply-button-color($theme-dark-color-green-success);
      }

      &-rejected {
        @include apply-button-color($theme-dark-color-red-alert);
      }

      svg {
        height: 16px;
        width: 16px;
      }
    }
  }

  .bookings-export {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .btn-export {
      font-size: 1rem;
    }

    > p {
      color: $color-white;
      font-size: 1rem;
    }

    &-sep {
      align-items: center;
      color: $color-white;
      display: flex;
      justify-content: center;
      text-align: center;
      position: relative;
      padding: 0.5rem 1rem;
      width: 90%;

      &:before,
      &:after {
        content: '';
        border-bottom: 1px dashed $color-white;
        height: 1px;
        position: absolute;
        top: 50%;
        width: 50%;
      }

      &:before {
        right: 50%;
        margin-right: 20px;
      }

      &:after {
        left: 50%;
        margin-left: 20px;
      }
    }
  }

  .pagination {
    background-color: $theme-dark-color-grey-85;
    min-height: 56px;
    padding: 0.75rem 1.5rem;
    width: 100%;
  }

  @media screen and (max-width: 1060px) {
    &-panel {
      .filter-bar-container {
        flex-wrap: wrap;
      }
    }

    .hbh-table {
      overflow-x: scroll;

      > div {
        min-width: 1120px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    header {
      flex-direction: column;
    }
  }
}

.dialog-bulk-assignment {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  .btn {
    width: 100%;

    &-process {
      @include apply-button-color($theme-dark-color-green-dark-1);
    }

    svg {
      height: 1rem;
      width: 1rem;
    }
  }
}
