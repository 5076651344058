@import '@assets/styles/variables.scss';

.field-checkbox {
  line-height: 0;

  .input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  .label {
    align-items: center;
    color: $color-grey-middle-1;
    cursor: pointer;
    display: inline-flex;
    position: relative;
    user-select: none;

    &::before {
      background-color: $color-white;
      background-repeat: no-repeat;
      background-position: center center;
      border: 1px solid $color-blue-primary;
      border-radius: $border-radius-small;
      content: '';
      display: inline-block;
      flex-shrink: 0;
      flex-grow: 0;
      height: 16px;
      margin-right: 10px;
      padding: 2px;
      width: 16px;
    }
  }

  .default {
    &::before {
      background-color: $color-white;
      border: 1px solid $border-color-light;
    }
  }

  .primary {
    &::before {
      background-color: $color-white;
      border: 1px solid $color-blue-primary;
    }
  }

  .secondary {
    &::before {
      background-color: $color-green-grass;
      border: 1px solid $color-green-grass;
    }
  }

  .white {
    color: $color-white;
    &::before {
      background-color: $color-blue-primary;
      border: 1px solid $color-white;
    }
  }

  .disabled {
    color: $color-grey-light-1;
    cursor: default;
    &::before {
      background-color: $color-grey-light-1;
      border: 1px solid $color-grey-light-1;
    }
  }

  .overlap {
    border-radius: $border-radius-small;
    padding: 5px 7px;

    &::before {
      background-color: $color-white;
      border: 1px solid $border-color-light;
    }
  }

  .input:checked + .default::before {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%236e7c8b' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    background-size: 75% 75%;
  }

  .input:checked + .primary::before {
    background-clip: content-box;
    background-color: $color-blue-primary;
  }

  .input:checked + .secondary::before {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    background-size: 75% 75%;
  }

  .input:checked + .white::before {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    background-size: 75% 75%;
  }

  .input:checked + .overlap {
    background-color: lighten($color-green-grass, 5%);
    color: $color-white;

    &::before {
      background-color: $color-green-grass;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
      background-size: 75% 75%;
      border: 1px solid $color-green-grass;
    }
  }

  .input:disabled + label {
    color: $color-grey-light-1;

    &::before {
      background-color: $color-grey-light-1;
    }
  }
}
