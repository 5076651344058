@import '@assets/styles/variables.scss';

.modal-confirmation {
  max-width: 420px;

  &-buttons {
    display: flex;
    justify-content: space-between;
  }

  &-message {
    font-size: 16px;
    margin: 10px 0;
    text-align: center;
  }
}
