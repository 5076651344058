@import '@assets/styles/variables.scss';

.invoice-form-panel {
  background-color: $color-white;
  border-radius: $border-radius-small;
  margin: 20px auto;

  &-header {
    background-color: $color-blue-primary;
    border-radius: $border-radius-small $border-radius-small 0 0;
    color: $color-white;
    padding: 10px 10px 15px 20px;

    > div {
      align-items: flex-end;
      display: flex;
      justify-content: space-between;

      small {
        font-size: 10px;
      }

      h3 {
        color: inherit;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  &-content {
    padding: 20px 20px 20px 25px;

    .hbh-table .ant-spin-nested-loading {
      overflow: initial;
    }
  }

  &-title-wrap {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &-title {
    align-items: center;
    color: $color-grey-dark-2;
    cursor: default;
    display: flex;
    font-size: 20px;
    font-weight: normal;
    line-height: 1.35;
    margin-bottom: 20px;

    svg {
      fill: $color-grey-middle-1;
      margin-right: 10px;
    }
  }
}
