@mixin apply-button-color($color) {
  background-color: $color !important;
  border-color: $color !important;

  &:hover {
    background-color: lighten($color, 10%) !important;
    border-color: lighten($color, 10%) !important;
  }
}

@mixin apply-status($color) {
  color: $color;

  &::before {
    background-color: $color;
  }
}

@mixin center-items {
  align-items: center;
  display: flex;
  justify-content: center;
}

@mixin image-wrapper-base {
  position: relative;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
}

@mixin text-cropping {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

// Use with px, rem, etc
@mixin image-wrapper($width, $height) {
  @include image-wrapper-base;
  width: $width;
  height: $height;
}

// Use with %
@mixin image-wrapper-responsive($width, $height) {
  @include image-wrapper-base;
  padding-top: $height;
  width: $width;
}

@mixin no-items-table-style {
  .ant-table-placeholder {
    align-items: center;
    background-color: $color-blue-light;
    padding: 20px;
    margin: 10px 0 25px;
    width: 100%;
    height: 60px;

    td {
      color: $color-grey-middle-1 !important;
      background-color: inherit !important;
      font-size: 14px !important;
      width: 100% !important;
    }
  }
}

@mixin padding-horizontal($padding) {
  padding-left: $padding;
  padding-right: $padding;
}
