@import '@assets/styles/variables.scss';

.invoice-history {
  background-color: $color-white;
  border-radius: $border-radius-small;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-family: $font-default;
  margin: 50px auto 0;
  max-width: 1098px;
  width: 100%;

  .row {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .footer {
    @extend .row;
    margin: 0 20px 20px;
  }

  .title {
    align-items: center;
    background-color: $color-blue-primary;
    border-radius: $border-radius-small $border-radius-small 0 0;
    color: $color-white;
    display: flex;
    font-size: 20px;
    padding: 10px 25px;
    line-height: 1.35;

    svg {
      margin-right: 10px;
    }
  }

  .filters-container {
    padding: 15px 25px;

    .form {
      align-items: flex-end;
      display: flex;
      justify-content: space-between;
      padding: 0 0 10px;
      width: 100%;

      .search {
        align-items: center;
        display: flex;
        width: 50%;

        .search-input {
          width: 100%;

          .input-text {
            border-radius: $border-radius-small 0 0 $border-radius-small;
          }
        }

        .btn-search {
          border-radius: 0 $border-radius-small $border-radius-small 0;
          width: 45px;
          height: 36px;
        }

        .btn-reset {
          color: $color-blue-primary;
          font-size: 12px;
          min-width: 95px;
        }
      }

      .filters {
        display: flex;
        justify-content: space-between;
        width: 28%;

        .month {
          width: 60%;
        }

        .year {
          width: 35%;
        }
      }
    }
  }

  table {
    border-collapse: collapse;
    white-space: nowrap;
    table-layout: fixed;
    margin: 0 0 20px;
    width: 100%;

    thead {
      tr {
        th {
          padding: 6px 6px 10px;

          &:first-child {
            padding-left: 20px;
          }
          &:last-child {
            width: 60px;
          }
        }
      }
    }

    tbody {
      tr {
        &:nth-child(odd) {
          background-color: rgba(152, 166, 181, 0.1);
        }
        td {
          border-right: 0;
          border-left: 0;
          overflow: hidden;
          text-overflow: ellipsis;

          &:first-child {
            padding-left: 20px;
          }
          &:nth-child(3) {
            text-transform: capitalize;
          }
          &:nth-child(11) {
            font-weight: bold;
          }
          &:nth-child(12) {
            color: $color-green-grass;
            padding: 0;
            text-align: center;
          }
          &:last-child {
            .btn-download {
              font-size: 12px;
              height: 28px;
              margin-right: 15px;
              min-height: auto;
            }
          }
        }
      }
    }
  }

  .status {
    color: $color-green-grass;
    text-transform: capitalize;
  }
}
