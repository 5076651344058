@import '@assets/styles/variables.scss';
@import '@assets/styles/extends.scss';

.travel-request-profile-page {
  max-width: 768px !important;
  padding: 1em 0 !important;

  .form-profile {
    display: flex;
    flex-direction: column;
    gap: 30px;

    fieldset {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      width: 100%;

      .field {
        flex: calc(50% - 20px);
        max-width: calc(50% - 10px);

        label {
          color: $color-white !important;
          font-size: 16px !important;
        }

        .hbh-date,
        .hbh-text-field {
          border-radius: 10px;
          font-size: 14px;
          height: 38px;

          &:disabled {
            background-color: $color-grey-light-1;
            border: 1px solid $color-grey-light-1;
          }
        }

        .hbh-select {
          .rs__control {
            border-radius: 10px;
            font-size: 14px;
            height: 38px;
          }

          .rs__indicator svg {
            fill: $color-grey-light-5;
          }
        }
      }
    }

    footer {
      display: flex;
      justify-content: space-between;
      margin: 15px 0 0;
      width: 100%;

      span {
        color: $color-white;
      }

      .btn {
        font-weight: bold;
        height: 38px;
        min-width: 160px;
      }
    }
  }
}
