@import '@assets/styles/extends.scss';
@import '@assets/styles/variables.scss';

$color-event-preview: #d97706;
$color-thead-bg: #19202b;

.fc-wrapper {
  margin: 0 0 30px;
  position: relative;

  .fc {
    font-family: $font-default;

    &-event-dragging {
      min-width: 28px;
    }

    &-header-toolbar.fc-toolbar {
      background-color: $color-grey-dark-3;
      height: 58px;
      margin-bottom: 0;
      padding: 12px 24px;

      .fc-toolbar-chunk {
        align-items: center;
        display: flex;

        .fc-button-group {
          margin-left: 1.25em;
        }

        @media screen and (min-width: 760px) {
          width: 33%;
        }
      }

      .fc-toolbar-chunk:first-child {
        padding-left: 125px;

        > button {
          background-color: transparent;
          border: 1px solid $color-white;
          font-size: 16px;
          padding: 5px 0;
          text-transform: capitalize;

          &:focus {
            box-shadow: none;
          }

          &.fc-prev-button,
          &.fc-next-button {
            align-items: center;
            border-radius: 50px;
            display: flex;
            height: 20px;
            justify-content: center;
            padding: 0;
            width: 20px;

            .fc-icon {
              font-size: 16px;
            }
          }

          &.fc-today-button {
            border: none;
          }
        }

        @media screen and (max-width: 760px) {
          display: none;
        }
      }

      .fc-toolbar-chunk:nth-child(2) {
        .fc-toolbar-title {
          color: $color-white;
          font-size: 18px;
          font-weight: bold;
        }

        @media screen and (min-width: 860px) {
          justify-content: center;
        }

        @media screen and (max-width: 760px) {
          padding-left: 50px;
        }
      }
    }

    &-view {
      background-color: $color-white;
      border-radius: 2px;
      min-height: 300px;
    }

    .fc-event {
      border: none;
      border-radius: 3px;
      margin: 1px;
      padding: 0;

      &-blue {
        background-color: $color-turquoise-light-3;
      }

      &-created-by-driver {
        background-color: $color-purple-middle-1;
      }

      &-driver-workflow {
        background-color: $color-yellow-dark-1;

        .fc-event-content {
          min-height: 16px;
        }
      }

      &-green {
        background-color: $color-green-grass;
      }

      &-grey {
        background-color: $color-grey-middle-1;
      }

      &-preview {
        background-color: $color-yellow-middle-1;
      }

      &-quest-workflow {
        background-color: $color-cyan;

        .fc-event-content {
          min-height: 16px;
        }
      }

      &-red {
        background-color: $color-red-light-1;
      }

      &-shuttle {
        background-color: $theme-dark-color-green-dark-1;
      }

      &-moved {
        background-color: $color-yellow-middle-1;
      }

      &-shuttle-route {
        background: none;
        margin-left: -2px;
        margin-right: -2px;

        &:has(.event-week-view) {
          .fc-event-content {
            min-height: auto;
            padding: 0.45em 0;
          }
        }

        &:not(:has(.event-week-view)) {
          &:after {
            content: '';
            background-color: #c9ddf24d;
            min-height: 600px;
            position: absolute;
            left: 0;
            right: -4px;
            top: 0;
            width: 100%;
            z-index: -1;
          }
        }

        .fc-event-content {
          min-height: 28px;
          justify-content: space-between;

          svg {
            fill: $color-white;
            height: 16px;
            width: 16px;
          }
        }

        .fc-event-main {
          background-color: $theme-dark-color-grey-8;
          border-radius: 3px;
        }
      }

      &-content {
        align-items: center;
        color: $color-white;
        display: flex;
        gap: 4px;
        min-height: 20px;
        overflow: hidden;
        padding: 2px;
        position: relative;
        white-space: nowrap;

        &.event-week-view {
          align-items: flex-start;
          flex-direction: column;
          gap: 2px;
          white-space: initial;

          > div {
            white-space: nowrap;
          }
        }

        > div:not(.icon) {
          align-items: center;
          display: flex;
          gap: 4px;

          &.agency-name {
            max-width: 80px;

            &::before {
              content: '';
              background-color: $color-white;
              border-radius: 50px;
              display: inline-block;
              height: 6px;
              width: 6px;
            }
          }

          &.drive-status {
            text-transform: capitalize;

            svg {
              fill: $color-white;
              height: 10px;
              width: 10px;
            }
          }
        }

        .icon {
          align-items: center;
          display: flex;
          height: 12px;
          width: 12px;

          svg {
            fill: $color-white;
            height: 100%;
            width: 100%;
          }
        }
      }

      &:has(.event-size-15) {
        max-width: 15px;
      }
    }

    .fc-scrollgrid {
      border: none;

      thead {
        background-color: $color-thead-bg;

        th {
          background-color: transparent;
          border: none;

          .fc-timeline-header {
            table.fc-scrollgrid-sync-table {
              min-width: auto !important;

              .fc-timeline-header-row {
                justify-content: center !important;

                th.fc-timeline-slot {
                  border-right: 2px solid $color-grey-dark-4 !important;
                  width: 40px;

                  .fc-timeline-slot-frame {
                    .fc-timeline-slot-cushion {
                      color: $color-white;
                      display: flex;
                      font-weight: 400;
                      font-size: 14px;
                      justify-content: center;
                      padding: 0;
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }

        .fc-resource-timeline-divider {
          background-color: $color-grey-dark-4;
        }
      }

      tbody {
        td {
          border-color: $color-grey-light-1;

          &:last-child {
            border-right: none;
            overflow: hidden;
          }
        }

        .fc-timeline-lane {
          border-color: $color-blue-light;
          border-width: 4px;
        }

        .fc-timeline-slot {
          border-width: 2px;
          width: 20px;
        }

        .fc-timeline-slot-minor {
          border-color: #f1f5f9;
          border-width: 1px;
        }

        .fc-resource-timeline-divider {
          background-color: $color-grey-light-1;
        }
      }

      .fc-resource {
        border-color: $color-blue-light;
        border-width: 4px;

        .fc-scrollgrid-sync-inner {
          padding: 0;
        }

        .fc-resource-content {
          display: flex;
          flex-direction: column;
          padding: 4px 6px;
          position: relative;

          &-desc {
            align-items: flex-start;
            color: $color-grey-dark-3;
            display: flex;
            gap: 4px;
            line-height: 1;
            padding: 0 0 2px;

            &-driver {
              display: flex;
              gap: 8px;
              position: relative;

              h3 {
                line-height: 1.6;
                white-space: normal;
              }

              .driver-photo {
                min-height: 75px;
                min-width: 75px;
                width: 75px;

                img {
                  height: 100%;
                  object-fit: cover;
                  overflow: hidden;
                  width: 100%;
                }

                svg {
                  height: 100%;
                  width: 100%;
                }
              }
            }

            h3 {
              font-size: 14px;
              font-weight: 600;
            }

            span {
              font-size: 12px;
            }

            .agency-logo {
              filter: brightness(0.2);
              height: 30px;
              text-indent: -999px;
            }
          }

          &-vehicle-image {
            height: 84px;
            margin: 0 0 8px;
            max-width: 140px;
            position: relative;

            > img {
              object-fit: cover;
            }

            .icon {
              height: 20px;
              position: absolute;
              top: 0;
              width: 20px;

              &.armoured {
                left: 0;
                svg {
                  fill: $color-yellow-middle-1;
                }
              }

              &.electric {
                left: 30px;
              }
            }
          }

          &.disabled {
            background-color: $color-grey-light-5;
          }

          &.shared {
            background-color: $color-turquoise-light-4;
          }

          &:hover {
            .btn-add-resource {
              display: flex;
            }
          }

          .btn-add-resource {
            align-items: center;
            background-color: $theme-dark-color-grey-1;
            border-radius: 50px;
            box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.16);
            display: none;
            justify-content: center;
            min-height: auto;
            position: absolute;
            right: 0.25rem;
            top: 0.25rem;
            height: 1.6rem;
            width: 1.6rem;
            z-index: 99;

            svg {
              fill: $theme-dark-color-accent;
              height: 0.75rem;
              width: 0.75rem;
            }
          }
        }
      }

      .fc-resource-timeline-divider {
        border: none;
        width: 2px;
      }
    }
  }

  .fc-content-empty {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 100px 0 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
}

.fc-event-dropdown {
  &[data-position='bottom'] {
    .drive-add-btn {
      top: -1.6rem;
    }
  }

  &[data-position='top'] {
    .drive-add-btn {
      bottom: -1.6rem;
    }
  }
}

.fc-event-popover {
  max-width: 600px;
  min-width: 400px;
  position: relative;

  .drive {
    &-add-btn {
      background-color: $theme-dark-color-grey-1;
      border-radius: 50px;
      box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.16);
      height: 2rem;
      width: 2rem;
      position: absolute;
      left: 50%;
      margin-left: -1rem;
      z-index: 99;

      svg {
        fill: $theme-dark-color-accent;
      }
    }

    &-conflicting {
      align-items: center;
      border-bottom: 2px solid $color-grey-light-1;
      display: flex;
      font-weight: bold;
      gap: 10px;
      line-height: 1.2;
      margin: 0 0 10px;
      padding: 0 0 5px;

      svg {
        height: 20px;
        width: 20px;
      }
    }

    &-schedule {
      align-items: center;
      border-bottom: 2px solid $color-grey-light-1;
      color: $color-grey-middle-1;
      display: flex;
      justify-content: space-between;
      line-height: 1.2;
      margin: 0 0 12px;
      padding: 0 0 5px;
    }

    &-info {
      display: flex;
      gap: 10px;

      .column {
        flex: 50%;

        &-flex {
          display: flex;

          section {
            min-width: 180px;
          }
        }

        section {
          margin: 0 0 10px;
        }

        .title {
          font-weight: 600;
        }

        .label {
          color: $color-grey-middle-1;
          margin-right: 5px;
        }
      }
    }
  }

  .shuttle-stops {
    align-items: center;
    display: flex;
    justify-content: space-around;
    margin: 1.2rem 0 0.5rem;
    position: relative;

    &:before {
      content: '';
      background-color: $theme-dark-color-grey-6;
      height: 1px;
      position: absolute;
      left: 0;
      right: 0;
      top: 5px;
    }

    li {
      align-items: center;
      display: flex;
      flex-direction: column;
      list-style-type: none;
      position: relative;

      &:before {
        content: '';
        background-color: $color-white;
        border: 1px solid $theme-dark-color-grey-6;
        border-radius: 50%;
        display: block;
        height: 12px;
        margin: 0 0 4px;
        width: 12px;
      }

      div {
        color: $theme-dark-color-grey-6;
        font-size: 10px;
      }

      span {
        color: $theme-dark-color-grey-8;
        font-size: 12px;
      }
    }
  }
}
