@import '@assets/styles/variables.scss';

.theme-light {
  .dropdown {
    &-input {
      border: 1px solid $theme-light-input-border;
      border-radius: $border-radius-small;
      color: $color-black;
      font-family: $font-default;
      padding: 0 0.75rem;

      &::placeholder {
        color: $theme-dark-color-grey-1;
      }

      &[data-with-icon] {
        padding-left: 2.2rem;
      }
    }

    &-icon {
      left: 8px;
      width: auto;
    }

    &-item {
      svg {
        margin-right: 0.5rem;
      }
    }
  }
}
