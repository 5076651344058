@import '@assets/styles/variables.scss';

.date-picker {
  position: relative;
}

.theme-dark {
  .date-picker {
    &-calendar {
      background-color: $color-white;
      border-radius: $border-radius-small;
      margin: 0.75rem 0 0.5rem;
      padding: 0.5rem 0.75rem;
    }

    label {
      color: $color-white;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
