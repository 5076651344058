@import '@assets/styles/mixins.scss';
@import '@assets/styles/variables.scss';

.modal {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  transition: all ease-in-out;
  width: 100%;
  z-index: 99;

  &-bg {
    background-color: rgba(36, 36, 36, 0.7);
    height: 100%;
    position: absolute;
    width: 100%;
  }

  &-header {
    align-items: center;
    display: flex;
    position: relative;

    .btn-close {
      cursor: pointer;
      height: 100%;
      position: absolute;
      z-index: 999;

      svg {
        fill: $color-white;
        height: 1rem;
        width: 1rem;
      }
    }

    .title {
      align-items: center;
      color: $color-white;
      cursor: default;
      display: flex;
      font-size: 14px;
      font-weight: 400;
      min-height: 40px;
      padding: 0 15px;
      width: 100%;
    }
  }

  &-box-default {
    background-color: $color-white;
    min-width: 460px;
    position: relative;
    z-index: 999;

    .modal-content {
      padding: 20px;
    }

    .title {
      background-color: $color-blue-primary;
    }
  }

  &-box-dark {
    background-color: $theme-dark-color-grey-9;
    border: 1px solid $theme-dark-color-grey-6;
    border-radius: $border-radius-md;
    min-width: 500px;
    padding: 1rem 2.25rem 2rem;
    position: relative;
    z-index: 999;

    .btn-close {
      margin-left: auto;
      margin-right: -1rem;
      position: relative;

      svg {
        fill: $color-grey-light-1;
        height: 1.25rem;
        width: 1.25rem;
      }
    }

    .modal-content {
      padding: 0;
    }

    .title {
      background-color: transparent;
      font-size: 22px;
      font-weight: 500;
      padding: 0 45px 0 0;
    }
  }

  &-box-light {
    background-color: $color-white;
    border-radius: 8px;
    min-width: 500px;
    padding: 15px 20px;
    position: relative;
    top: -2rem;
    z-index: 999;

    .btn-close {
      position: absolute;
      right: 12px;
      top: 6px;

      svg {
        fill: $theme-dark-bg-accent;
        height: 22px;
        width: 22px;
      }
    }

    .modal-content {
      padding: 0;
    }

    .title {
      background-color: transparent;
      color: $color-grey-middle-1;
      font-size: 18px;
      font-weight: 500;
      padding: 0;
    }
  }

  &-box-confirm {
    background-color: $color-white;
    min-width: 500px;
    padding: 0;
    position: relative;
    z-index: 999;

    .modal-header {
      .btn-close {
        right: 10px;

        svg {
          fill: $color-white;
          height: 18px;
          width: 18px;
        }
      }

      .title {
        background-color: $color-grey-dark-3;
        font-size: 18px;
        padding: 14px 20px;
      }
    }

    .modal-content {
      font-size: 18px;
      line-height: 1.8;
      padding: 30px 20px 20px;
      text-align: center;
    }

    .modal-footer {
      display: flex;
      justify-content: space-between;
      padding: 15px 20px;

      .btn {
        min-width: 60px;
      }

      .btn-close {
        @include apply-button-color($color-red-dark-1);
      }
    }
  }
}
