@import '@assets/styles/mixins.scss';
@import '@assets/styles/variables.scss';

.modal-route-reject {
  display: flex;
  flex-direction: column;
  width: 100%;

  &.loading {
    opacity: 0.75 !important;
    pointer-events: none;
  }

  .title {
    font-size: 1.125rem;
    font-weight: 400;
  }

  section {
    min-width: 620px;
    padding: 2rem 0 0;
  }

  footer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 2rem 0 0;

    .btn {
      min-width: 140px;

      &-reject {
        @include apply-button-color($theme-dark-color-red-alert);
      }
    }
  }
}
