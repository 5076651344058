@import '@assets/styles/variables.scss';
@import '@assets/styles/extends.scss';

.flex {
  display: flex;
  justify-content: space-between;
}

.trips-details {
  color: $color-grey-dark-2;
  display: flex;
  flex-direction: column;

  .row {
    @extend .flex;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  .table {
    @extend .invoice-table;

    table {
      border-spacing: 4px;
      margin: 8px -4px 10px;
      table-layout: fixed !important;

      thead {
        tr {
          th {
            padding: 10px 4px 12px !important;

            &:first-child {
              padding-left: initial !important;
              width: 75px;
            }
            &:nth-child(2) {
              width: 126px;
            }
            &:nth-child(7) {
              width: 26px;
            }
            &:nth-child(10) {
              width: 100px;
            }
            &:nth-child(11) {
              width: 100px;
            }
            &:nth-child(12) {
              width: 50px;
            }
            &:nth-child(13) {
              width: 45px;
            }
            &:nth-child(14) {
              width: 60px;
            }
            &:last-child {
              width: 65px;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            overflow: hidden;
            @extend .invoice-table;

            &:last-child {
              border: 0;
              padding: 0;
              width: 65px;
            }
          }
        }
      }
    }
  }
}
