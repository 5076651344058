@import '@assets/styles/variables.scss';

.ddo-footer {
  align-items: center;
  background-color: #333;
  box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
  display: flex;
  font-family: $font-default;
  font-size: 14px;
  height: 60px;
  justify-content: space-between;
  line-height: 1.4;
  padding: 5px 40px;

  .logo {
    height: 42px;
  }

  .powered {
    margin-right: 18px;
  }

  a,
  span {
    color: $color-grey-light-7;

    &:hover {
      color: $color-grey-light-7;
    }
  }
}
