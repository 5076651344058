.page-loader {
  &:not(&-single) {
    position: relative;
    width: 100%;
  }

  &-spinner {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    min-height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9999;
  }

  &-content-wrap {
    opacity: 0.5;
    pointer-events: none;
    position: relative;
    transition: opacity 0.3s;
    user-select: none;
  }

  &-full-screen {
    position: fixed;
  }

  &-single:has(&-full-screen) {
    &:after {
      content: '';
      background-color: rgba(0, 0, 0, 0.25);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      z-index: 99;
    }
  }
}
