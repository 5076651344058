@import '@assets/styles/mixins.scss';
@import '@assets/styles/variables.scss';

@mixin divider {
  position: relative;

  &::after {
    content: '';
    background-color: $color-grey-dark-5;
    display: block;
    height: 1px;
    margin-left: -20px;
    width: calc(100% + 20px * 2);
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.driver-info-popup {
  color: $color-white;
  font: inherit;
  height: 650px;
  position: fixed;
  right: 0;
  top: 50%;
  overflow-y: auto;
  width: 370px;
  animation: fade-in 0.2s;
  transform: translateY(-50%);

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .header {
    align-items: center;
    background-color: $color-yellow-middle-1;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: flex;
    padding: 8px 20px;

    h2 {
      font-size: 20px;
      font-weight: 300;
    }

    button {
      background: none;
      border: 0;
      margin-left: auto;
      padding: 0;

      .close-icon {
        fill: $color-white;
        height: 12px !important;
        width: 12px !important;
      }
    }

    &.critical {
      background-color: $color-red-light-3;
    }

    &.issue {
      background-color: $color-yellow-middle-1;
    }

    .icons {
      @include center-items;
      border-radius: 4px;
      height: 34px;
      margin-right: 12px;
      width: 34px;

      &.critical {
        background-color: #e3401d;
      }

      &.issue {
        background-color: #e2800d;
      }
    }
  }

  .content {
    background-color: $color-grey-dark-4;
    border: 1px solid $color-grey-dark-4;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    min-height: 400px;
    padding: 20px;

    > *:not(:last-child) {
      padding-bottom: 14px;
    }

    h3,
    h4,
    h5 {
      font-weight: 400;
      margin: 0;
    }

    h3 {
      font-size: 24px;
    }

    h4 {
      font-size: 14px;
    }

    h5 {
      color: #bfbfbf;
      font-size: 11px;
    }

    .main-info {
      @include divider;

      .date {
        color: white;
        margin-bottom: 14px;
      }

      .driver {
        color: white;
        margin-bottom: 10px;
      }

      .vehicle {
        display: flex;
        margin-top: 8px;
        margin-bottom: 20px;

        h4 {
          color: white;
        }

        .image {
          height: 37px;
          margin-right: 5px;
          width: 75px;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  .critical-issue {
    margin-right: 5px;
  }

  .issues {
    .issue {
      list-style: none;
      padding: 10px 20px;
      @include divider;

      &::before {
        content: '';
        background-color: $color-white;
        border-radius: 50%;
        display: inline-block;
        height: 5px;
        margin-right: 10px;
        width: 5px;
        vertical-align: middle;
      }

      img {
        border-radius: 10px;
        display: block;
        height: 100px;
        margin-top: 5px;
        max-width: 150px;
      }

      q {
        display: block;
        font-size: 12px;
        font-style: italic;
        margin-left: 30px;
        quotes: '“' '”';
      }
    }
  }
}
