@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.scss';
@import '@assets/styles/extends.scss';

.charges-container {
  color: $color-grey-dark-2;
  display: flex;
  flex-direction: column;

  .row {
    @extend .flex;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  .options {
    @extend .flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;

    > div {
      margin-right: 20px;
    }

    .dropdown {
      min-width: 200px;
    }
  }

  .footer {
    @extend .flex;
    margin: 15px 0 10px;
  }

  .table {
    @include no-items-table-style;
    @extend .invoice-table;

    table {
      border-spacing: 10px;
      border-collapse: initial !important;
      table-layout: fixed;
      white-space: nowrap;

      thead {
        tr {
          th {
            &:first-child {
              width: 52%;
              padding-left: 4px !important;
            }

            &:nth-child(2) {
              width: 21%;
            }

            &:nth-child(3) {
              width: 21%;
            }

            &:last-child {
              width: 6%;
            }
          }
        }
      }
    }
  }

  .form {
    border-spacing: 10px;
    display: table;
    margin: 0 -10px 0 -10px;
    table-layout: fixed;
    white-space: nowrap;

    &-row {
      display: table-row;
    }

    &-cell {
      display: table-cell;
      vertical-align: top;
      position: relative;

      input + small[role='alert'] {
        position: absolute;
        bottom: -15px;
        left: 0;
      }

      &:first-child {
        width: 50%;
      }

      &:nth-child(2) {
        width: 20%;
      }

      &:nth-child(3) {
        width: 20%;
      }

      &:last-child {
        width: 10%;
      }
    }

    &-actions {
      position: relative;
      width: 68px;

      .confirm {
        padding: 6px 8px;
      }

      .delete {
        position: absolute;
        z-index: 2;
        top: 7px;
        right: -30px;

        svg {
          fill: $color-grey-middle-1;
          transition: 0.2s fill linear;

          &:hover {
            fill: $color-blue-primary;
          }
        }
      }
    }
  }
}
