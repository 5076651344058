@import '@assets/styles/variables.scss';

@mixin dark-theme {
  &-icon svg {
    fill: $color-white;
  }

  &-input,
  &-label {
    color: $color-white;
  }

  &-wrapper {
    background-color: $theme-dark-color-grey-9;
    border: 1px solid $color-grey-dark-5;
  }
}

@mixin dark-theme-dropdown {
  &-control,
  &-month,
  &-select {
    color: $color-white !important;

    &:hover {
      background-color: transparent;
    }
  }

  &-day {
    color: $color-white;

    &[data-selected],
    &:hover {
      background-color: $color-blue-primary !important;
    }

    &[data-in-range] {
      background-color: lighten($color-blue-primary, 20%);
    }
  }
}

.date-input-picker {
  &-dropdown-day {
    &[data-weekend] {
      color: $color-black;
    }

    &[data-selected] {
      color: $color-white;
    }
  }

  &-input {
    color: $color-white;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    min-width: 180px;
    overflow: hidden;
    padding: 6px 0 !important;
    position: relative;
    text-align: center;
    z-index: 1;
  }

  &-icon {
    height: 20px;
    left: initial;
    margin-top: -10px;
    top: 50%;
    width: 20px;
    z-index: 0;

    &-left {
      left: 10px;

      + .date-input-picker-input {
        padding-left: 26px !important;
      }
    }

    &-right {
      right: 10px;

      + .date-input-picker-input {
        padding-right: 26px !important;
      }
    }

    svg {
      fill: $color-white;
    }
  }

  &-label {
    display: block;
    font-family: $font-default;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.4;
    margin-bottom: 8px;
  }

  &-month {
    font-size: 16px;
  }

  &-wrapper {
    align-items: center;
    border-radius: $border-radius-small;
    display: flex;
    padding: 1px 10px;
    position: relative;
  }

  &.icon-only {
    .date-input-picker-wrapper {
      overflow: hidden;
    }

    .date-input-picker-input {
      text-indent: -999px;
    }
  }
}

.date-input-picker-dark {
  .date-input-picker {
    @include dark-theme;

    &-icon {
      &-left {
        + .date-input-picker-input {
          span {
            color: $color-white;
          }
        }
      }
    }
  }
}

.date-input-picker-default {
  &.icon-only {
    .date-input-picker-input {
      min-width: 30px;
      padding: 2px 8px !important;
    }
  }

  .date-input-picker {
    &-icon {
      &-left {
        left: 5px;
      }

      &-right {
        right: 0;
      }

      svg {
        fill: $color-white;
      }
    }

    &-wrapper {
      padding: 0;
    }
  }
}

.date-input-picker-light {
  &.icon-only {
    .date-input-picker-input {
      min-width: 160px;
    }
  }

  .date-input-picker {
    &-wrapper {
      border: 1px solid $color-white;
    }
  }
}

.date-input-picker-outline {
  .date-input-picker {
    &-wrapper {
      background-color: transparent;
      border: 1px solid $color-white;
    }
  }
}

.date-input-picker-theme-dark {
  .date-input-picker {
    &-icon {
      &-left {
        left: 0;

        + .date-input-picker-input {
          padding: 8px 0 7px 30px !important;
          text-align: left;

          span {
            color: $color-white;
          }

          &[data-disabled] {
            background: transparent;
          }
        }
      }

      &-right {
        right: 0;
      }

      svg {
        fill: $color-white;
      }
    }

    &-wrapper {
      background-color: transparent;
      padding: 0;
    }
  }
}

.mantine-Popover-dropdown {
  th,
  td {
    border: none !important;
  }

  &:has(.date-input-picker-dropdown-dark, .date-input-picker-dropdown-outline, .date-input-picker-dropdown-theme-dark) {
    background-color: $color-grey-dark-4 !important;
    border: 1px solid $color-grey-dark-5 !important;

    .date-input-picker {
      @include dark-theme;
    }

    .date-input-picker-dropdown {
      @include dark-theme-dropdown;
    }
  }
}
