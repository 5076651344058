@import '@assets/styles/variables.scss';

.invoice-send-copy {
  align-items: flex-start;
  display: flex;
  margin: 10px 5px 30px 0;

  .select {
    width: 100%;
  }
}
