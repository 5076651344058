@import '@assets/styles/variables.scss';

.review-rating {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 2px 4px;
  position: relative;

  &.left {
    justify-content: center;
    margin: 0;
    margin-left: 13px;
    min-height: 108px;

    .review-rating-value {
      font-size: 30px;
    }
  }

  &.right {
    justify-content: center;
    margin: 0;
    margin-right: 13px;
    min-height: 108px;

    .review-rating-value {
      font-size: 30px;
    }
  }

  &-value {
    font-family: $font-default;
    font-size: 50px;
    font-weight: bold;
    line-height: 42px;
    text-align: center;
  }

  &-arrow {
    border-style: solid;
    height: 0;
    position: absolute;
    width: 0;

    &.arrow-left {
      border-width: 7.5px 15px 7.5px 0;
      left: -15px;
    }

    &.arrow-right {
      border-width: 7.5px 0 7.5px 15px;
      right: -15px;
    }
  }
}
