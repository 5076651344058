@import '@assets/styles/mixins.scss';
@import '@assets/styles/variables.scss';

.modal-bulk-assign {
  display: flex;
  flex-direction: column;
  width: 100%;

  &.loading {
    opacity: 0.75 !important;
    pointer-events: none;
  }

  .title {
    font-size: 1.125rem;
    font-weight: 400;
  }

  .bulk-assign {
    min-width: 620px;
    padding: 2rem 0 0;

    &-booking {
      align-items: center;
      border-bottom: 1px solid $theme-dark-color-grey-7;
      display: flex;
      gap: 1.25rem;
      margin: 0 0 1rem;
      padding: 0 0 1rem;

      &-col {
        color: $color-white;
        font-size: 0.875rem;

        > section {
          display: flex;
          gap: 1rem;
          padding: 0.25rem 0;

          &:not(:last-child) {
            border-bottom: 1px dashed $theme-dark-color-grey-7;
          }

          div {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 180px;
          }
        }

        svg {
          fill: $color-white;
        }

        .sub {
          color: $theme-dark-color-grey-5;
          font-size: 0.8rem;
          font-style: normal;
          font-weight: 400;
        }

        .warn {
          @include apply-status($theme-dark-color-red-alert);
        }
      }

      &.disabled {
        opacity: 0.7;
      }
    }
  }

  .footer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 2rem 0 0;

    .btn {
      min-width: 140px;

      &-process {
        @include apply-button-color($theme-dark-color-green-dark-1);

        svg {
          height: 1rem;
          width: 1rem;
        }
      }
    }
  }
}
