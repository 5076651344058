@import '@assets/styles/variables.scss';

.status-selector {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  &-btn {
    align-items: center;
    border-radius: 10px;
    border: 0;
    color: $color-white;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    justify-content: space-between;
    outline: 0;
    padding: 6px 10px;
    width: 100%;

    &.open {
      background-color: #878787 !important;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &-label {
      text-align: center;
      flex-grow: 1;
    }

    &-icon {
      display: flex;

      svg {
        fill: $color-white;
      }
    }
  }

  &-dropdown {
    background-color: #878787;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin: 0;
    padding: 0 12px 10px;
    text-align: left;
    width: 100%;
    z-index: 9;

    > button {
      background-color: transparent;
      border: 0;
      color: $color-white;
      cursor: pointer;
      padding: 5px 0;
      text-align: left;
      width: 100%;

      &[disabled] {
        opacity: 0.5;
      }

      &:before {
        content: '';
        border: 1px solid #707070;
        border-radius: 50px;
        display: inline-block;
        height: 10px;
        margin-right: 10px;
        width: 10px;
      }

      &:hover {
        &:before {
          background-color: $color-white;
        }
      }

      &.current {
        &:before {
          background-color: $color-white;
        }
      }

      &:first-child {
        border-top: 1px solid rgba(53, 54, 55, 0.45);
        padding: 15px 0 5px;
      }
    }
  }
}
