@import '@assets/styles/variables.scss';

.vehicles-checklist {
  margin: 0 20px;

  &::before {
    content: '';
    background-color: #000;
    opacity: 0.6;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }

  &-loader {
    min-height: 450px;
  }

  &-header {
    color: $color-white;
    display: flex;
    justify-content: space-between;
    margin: 30px 0 25px;

    h1 {
      border-bottom: 1px solid $color-grey-dark-1;
      color: $color-white;
      font-size: 24px;
      font-weight: 300;
      padding-bottom: 16px;
      line-height: 2em;
      margin-bottom: 16px;

      span {
        font-weight: 500;
      }
    }
  }

  &-filters {
    width: 500px;

    .filters {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .dropdown {
        font-size: 14px;

        .rs__control {
          height: auto;
        }
      }

      .driver,
      .vehicle {
        width: 35%;
      }

      .btn-reset {
        color: $color-blue-primary;
        font-size: 14px;
        text-decoration: underline;
        width: 22%;
      }
    }
  }

  &-info {
    display: flex;

    &-overview {
      display: flex;
      margin-bottom: 20px;

      .overview {
        display: flex;

        h3 {
          color: white;
          font-size: 18px;
          font-weight: 500;
          margin-right: 24px;
        }

        .dropdown {
          min-width: 100px;
        }

        & > * {
          margin-right: 10px;
        }
      }

      .checklist {
        display: grid;
        grid-template-columns: 50% 50%;
        list-style: none;
        margin: 0;
        padding: 0;
        margin-top: 20px;

        li {
          margin-right: 36px;
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          flex-wrap: nowrap;
          width: 100%;

          &::before {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-right: 10px;
            margin-bottom: 2px;
          }

          &.clear::before {
            background-color: #6a9826;
            border: none;
          }
          &.not-reported::before {
            background-color: #333536;
            border: 1px solid #f26c4f;
          }
          &.not-used::before {
            background-color: #333536;
            border: 1px solid #707070;
          }
          &.critical::before {
            background-color: #ff6666;
            border: none;
          }
          &.issues::before {
            background-color: #f5a623;
            border: none;
          }
        }
      }

      .daily-checks {
        text-align: center;
        margin-left: 36px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h2 {
          color: white;
          font-size: 30px;
          font-weight: 500;
        }

        small {
          font-size: 10px;
        }
      }
    }
  }
}
