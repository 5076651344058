@import '@assets/styles/mixins.scss';
@import '@assets/styles/variables.scss';

.dialog-event-drop-preview {
  align-items: flex-start;
  display: flex;
  font-family: $font-default;
  flex-direction: column;
  max-width: 500px;
  min-width: 420px;
  padding: 0;
  width: auto;

  header,
  footer,
  ul {
    padding: 0.5rem 1rem;
    width: 100%;
  }

  header {
    border-bottom: 1px solid #e7e7e7;

    h2 {
      font-size: 1rem;
      font-weight: 500;
    }
  }

  ul.event-drop-preview {
    margin: 0.75rem 0 0;
    min-height: 5rem;

    li {
      align-items: center;
      display: flex;
      gap: 1rem;
      margin: 0 0 0.5rem;

      > div {
        color: $theme-dark-color-grey-9;
        font-size: 0.85rem;
        font-weight: 600;
        min-width: 6rem;
      }

      span {
        color: $theme-dark-color-grey-9;
        font-size: 1rem;
      }

      p {
        span {
          display: block;

          &:not(:first-child) {
            font-size: 0.75rem;
          }
        }
      }

      svg {
        fill: $theme-dark-color-grey-6;
        height: 1rem;
        width: 1rem;
      }
    }
  }

  footer {
    border-top: 1px solid #e7e7e7;
    display: flex;
    gap: 3rem;
    justify-content: space-between;

    .btn {
      &-discard {
        @include apply-button-color($theme-dark-color-green-dark-1);
      }
      &-edit {
        @include apply-button-color($color-blue-secondary);
        min-width: 6rem;
      }
      &-confirm,
      &:last-child {
        @include apply-button-color($color-yellow-middle-1);
      }
    }
  }
}
