@import '@assets/styles/variables.scss';

.mobility-logbook-details-page {
  .mlb {
    &-nav {
      align-items: center;
      color: $color-white;
      display: flex;
      gap: 12px;
      margin: 20px 0 0 20px;

      &-back {
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
    }

    &-details-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 24px 24px 64px;
      width: 100%;

      .details-row {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }

      .row-centered {
        align-items: center;
        display: flex;
      }
    }

    &-details-left-content {
      width: 684px;
      display: flex;
      flex-direction: column;
    }
  }
}
