@import '@assets/styles/variables.scss';

$background-color: #34343499;
$bg-event-color: #707070;
$border-color: #3c3c3c;

.fc-calendar-vehicles-checklist {
  .fc {
    background: $background-color;
    border: 1px solid $border-color;
    border-radius: 3px;
    position: relative;
    z-index: 0;

    &-toolbar {
      &.fc-header-toolbar {
        height: 50px;
        margin-bottom: 0;
        padding: 5px 20px;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 9;

        .fc-toolbar-chunk {
          align-items: center;
          display: flex;

          &:first-child {
            position: relative;

            > button {
              background-color: transparent;
              border: 2px solid $color-white;
              font-size: 16px;
              padding: 5px 0;
              text-transform: capitalize;

              &:focus {
                box-shadow: none;
              }

              &.fc-prev-button,
              &.fc-next-button {
                align-items: center;
                border-radius: 50px;
                display: flex;
                height: 24px;
                justify-content: center;
                padding: 0;
                width: 24px;

                .fc-icon {
                  font-size: 16px;
                  font-weight: 600;
                }
              }

              &.fc-prev-button {
                margin-left: 70px;
              }
            }

            .fc-toolbar-title {
              align-items: center;
              color: $color-white;
              display: flex;
              font-size: 14px;
              font-weight: 400;
              min-width: 160px;
              padding: 5px 0 0 42px;
            }

            @media screen and (max-width: 760px) {
              display: none;
            }
          }

          .fc-toolbar-calendar {
            height: 40px;
            margin: 0;
            position: absolute;
            left: 20px;
            top: 8px;

            .date-range-picker-input {
              min-width: 200px;
            }
          }

          @media screen and (min-width: 760px) {
            width: 33%;
          }
        }
      }
    }

    table.fc-scrollgrid {
      border: none;

      thead {
        .fc-scrollgrid-section-sticky {
          & > * {
            background-color: transparent;
            border: none;
          }

          > th,
          > td {
            border-bottom: 2px solid $border-color !important;
          }

          th {
            border: none;

            .fc-timeline-header {
              table.fc-scrollgrid-sync-table {
                min-width: auto !important;

                .fc-timeline-header-row {
                  justify-content: center !important;

                  th.fc-timeline-slot {
                    .fc-timeline-slot-frame {
                      .fc-timeline-slot-cushion {
                        color: $color-white;
                        cursor: default;
                        line-height: 1.2;
                        white-space: normal;
                        width: 40px;
                      }
                    }
                  }
                }
              }
            }

            .fc-datagrid-header,
            .fc-scrollgrid-sync-table {
              height: 54px;
            }

            .fc-timeline-slot-frame {
              height: 52px !important;
            }
          }
        }

        .fc-resource-timeline-divider {
          & + th {
            padding: 0 30px;
          }
        }
      }

      tbody {
        .fc-event {
          background-color: transparent;
          border: none;
          height: 95px;
          margin: 10px 2px;
          padding: 0;

          &-main {
            align-items: center;
            display: flex;
            height: 100%;
            justify-content: center;

            .fc-event-content {
              border-radius: 4px;
              height: 100%;
              max-width: 120px;
              width: 100%;
              overflow: hidden;

              &-clear {
                background-color: $color-green-dark-1;
              }

              &-critical {
                background-color: $color-red-light-1;
              }

              &-issues {
                background-color: $color-yellow-middle-1;
              }

              &-not-reported {
                background-color: $color-grey-middle-1;
              }

              &-not-used {
                align-items: center;
                border: 1px solid $bg-event-color;
                display: flex;
                justify-content: center;
                position: relative;

                &::after {
                  background-color: $bg-event-color;
                  content: '';
                  filter: blur(25px);
                  height: 100%;
                  opacity: 0.7;
                  position: absolute;
                  width: 100%;
                  z-index: -1;
                }
              }

              h4 {
                color: $color-white;
                font-size: 10px;
                font-weight: 700;
                margin: 0 0 22px;
                overflow: hidden;
                padding: 4px;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
              }
            }
          }

          &-header {
            border-radius: 3px 3px 0px 0px;
            font-size: 11px;
            font-weight: 600;
            padding: 2px 4px;

            &-clear {
              background-color: #517221;
            }

            &-critical {
              background-color: #e25131;
            }

            &-issues {
              background-color: #e2800d;
            }

            &-not-reported {
              background-color: #383131;
              border: 1px solid $color-red-light-3;
            }
          }

          &-desc {
            display: flex;
            flex-direction: column;

            &-status {
              align-items: center;
              display: flex;
              justify-content: space-between;
              padding: 0 4px;
            }

            &-icon {
              align-items: center;
              border-radius: 4px;
              display: flex;
              height: 24px;
              justify-content: center;
              width: 24px;

              &-clear {
                background-color: #517221;
              }

              &-issues {
                background-color: #e2800d;
              }

              &-critical {
                background-color: #e3401d;
              }

              &-not-reported {
                background-color: #383131;
              }
            }
          }
        }

        .fc-resource {
          border: 1px solid $border-color;

          .fc-scrollgrid-sync-inner {
            padding: 0;
            white-space: normal;
          }

          .fc-resource-content {
            align-items: center;
            color: $color-white;
            display: flex;
            gap: 8px;
            min-height: 115px;
            padding: 10px 12px;

            &-vehicle-image {
              align-items: center;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              margin: 0;
              min-height: 90px;
              min-width: 110px;
              position: relative;
              width: 110px;

              > img {
                object-fit: cover;
              }
            }

            &-vehicle-protection {
              color: $color-white;
              font-size: 14px;
            }

            &-desc {
              align-items: flex-start;
              display: flex;
              gap: 4px;
              line-height: 1;
              padding: 0 0 2px;

              h3 {
                color: $color-white;
                font-size: 16px;
                font-weight: 500;

                p {
                  color: $color-white;
                  font-size: 12px;
                  padding: 8px 0 0;

                  &.critical {
                    color: $color-red-light-1;
                  }

                  &.issues {
                    color: $color-yellow-middle-1;
                  }
                }
              }

              span {
                font-size: 12px;
              }

              .agency-logo {
                filter: brightness(0.2);
                height: 30px;
                text-indent: -999px;
              }
            }
          }
        }

        .fc-scrollgrid-section-body {
          > td {
            border: none;
          }
        }

        .fc-scrollgrid-sync-table {
          .fc-timeline-lane {
            .fc-timeline-lane-frame {
              &:before {
                background-color: rgba(255, 255, 255, 0.8);
                content: '';
                display: block;
                filter: blur(0.5px);
                height: 1px;
                left: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
              }
            }
          }
        }

        .fc-resource-timeline-divider {
          background-color: $border-color;
          border: none;
          width: 0px;

          & + td {
            padding: 0 30px;
          }
        }

        .fc-timeline-slots {
          td {
            border: none;
          }
        }
      }
    }
  }
}
