@import '@assets/styles/mixins.scss';
@import '@assets/styles/variables.scss';

.mobility-logbook-page {
  margin-top: 45px !important;
  max-width: $width-xxxl !important;
  padding: 0 10px;

  .page-title {
    align-items: center;
    display: flex;
    gap: 15px;
    margin: 0;

    > div {
      display: flex;

      :last-child {
        margin: 0;
      }
    }

    > section {
      width: 100%;
    }

    .btn {
      padding: 18px 8px;

      &-export {
        @include apply-button-color($color-green-middle-1);

        .icon {
          margin-right: 5px;
        }
      }

      svg {
        height: 14px;
        width: 14px;
      }
    }
  }

  .table {
    margin-bottom: 25px;

    .hbh-table-dark {
      tr {
        th {
          font-size: 14px !important;
          padding: 16px 8px;
          text-transform: uppercase;
          vertical-align: top;
        }

        td {
          height: 80px;
          overflow-wrap: break-word;
          padding: 16px 8px;
          vertical-align: top;
          white-space: pre-wrap;

          .col-pickup-time {
            align-items: center;
            display: flex;

            span {
              margin-left: 8px;
            }
          }
        }
      }
    }

    .btn {
      font-size: 12px;
      height: 24px;
      min-width: 90px;
      position: relative;
      width: 100%;

      &:hover {
        color: $color-white;
      }

      &.btn-details {
        align-items: center;
        background-color: $color-grey-dark-4;
        border: 1px solid $color-grey-dark-5;
        border-radius: $border-radius-small;
        display: flex;
        color: $color-white;
        cursor: pointer;
        height: 40px;
        justify-content: center;
        min-width: 40px;
        width: 40px;
      }
    }

    tbody {
      tr {
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .table {
      overflow-x: scroll;

      > div {
        min-width: 1200px;
      }
    }
  }
}
