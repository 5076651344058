@import '@assets/styles/variables.scss';

.last-ratings {
  border-top: 1px solid #ffffff60;
  color: $color-white;
  display: flex;
  flex-direction: column;
  padding: 0 0 10px;
  width: 100%;

  &-header {
    color: $color-white;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    padding: 12px 0 5px;
    text-align: center;
    text-transform: uppercase;
  }

  &-item {
    display: flex;
    flex-direction: column;
    margin: 5px 0;

    &:not(:last-child):after {
      content: '';
      background-color: #ffffff40;
      display: flex;
      height: 1px;
      width: 65%;
      margin-top: 8px;
    }

    &-date {
      color: #ffffff60;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0.33px;
    }

    &-rating {
      font-size: 18px;
      font-weight: bold;
      line-height: 1.35;
      min-width: 24px;
      text-align: left;
    }

    &-ref {
      font-size: 14px;
      margin-right: 10px;
      min-width: 60px;
      text-align: left;
      text-transform: uppercase;
    }
  }

  .row {
    align-items: center;
    display: flex;
    margin: 0;
  }
}
