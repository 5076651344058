@import '@assets/styles/variables.scss';

.filter-bar {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &-container {
    align-items: center;
    display: flex;
    gap: 1.2rem;

    h2 {
      color: $color-white;
      font-size: 1rem;
    }

    svg {
      fill: $color-white;
    }

    .btn-clear {
      color: $theme-dark-color-grey-6;
      font-size: 1rem;
      font-weight: 400;

      svg {
        fill: $theme-dark-color-grey-6;
      }
    }

    .date-input-picker {
      &-input {
        max-height: 36px;
        min-width: 200px;

        span {
          font-size: 1rem;
        }
      }
    }

    .dropdown {
      max-width: 145px;

      &.status {
        max-width: 170px;
      }

      &-field {
        &-input {
          cursor: pointer;
        }
      }
    }
  }

  &-tags {
    display: flex;
    gap: 1rem;
  }
}
